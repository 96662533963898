import { clearSession, setSessionAppVersion } from "../session";
import { INVOICE_TYPE, TRANSACTION_TYPE } from "../../constants";

export function get_browser() {
    let ua = window.navigator.userAgent, tem,
        M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
        tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
        return {name: 'IE', version: (tem[1] || '')};
    }
    if (M[1] === 'Chrome') {
        tem = ua.match(/\bOPR|Edge\/(\d+)/)
        if (tem != null) {
            return {name: 'Opera', version: tem[1]};
        }
    }
    M = M[2] ? [M[1], M[2]] : [window.navigator.appName, window.navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) {
        M.splice(1, 1, tem[1]);
    }
    return {
        name: M[0],
        version: M[1]
    };
}

export function sortByDateDescending(array) {
    return array.sort((a, b) => {
        const dateA = new Date(a.sdcDateTime);
        const dateB = new Date(b.sdcDateTime);
        return dateB - dateA;
    });
}

export function getCounterFromInvoice(invoice) {
    let invoiceNumber = invoice.invoiceNumber;
    let index = invoiceNumber.lastIndexOf("-") + 1
    let counter = invoiceNumber.substring(index, invoiceNumber.length)
    let firstLetter;
    switch (invoice.invoiceType) {
        case INVOICE_TYPE.training:
            firstLetter = "О"
            break;
        case INVOICE_TYPE.copy:
            firstLetter = "К"
            break;
        case INVOICE_TYPE.normal:
            firstLetter = "П"
            break;
        case INVOICE_TYPE.advance:
            firstLetter = "А"
            break;
        case INVOICE_TYPE.proforma:
            firstLetter = "Р"
            break;
        default:
            firstLetter = "#"
            break;
    }
    let secondLetter = invoice.transactionType === TRANSACTION_TYPE.sale ? "П" : "Р";
    return `${counter}/${firstLetter}${secondLetter}`
}
export function clearCache(appVersion) {
    if ('caches' in window) {
        caches.keys().then(async (names) => {
            // Delete all the cache files
            for (const name of names) {
                await caches.delete(name);
            }
        });
    }
    clearSession()
    window.location.reload(true);
    setSessionAppVersion(appVersion)
}

export function taxValueForLabel(item, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        const found = currentTaxRates[i].taxRates.find(ct => ct.label === item.vat)
        if (found) {
            tax = found.rate
        }
    }
    return tax
}

export function taxValueForSingleLabel(label, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        const found = currentTaxRates[i].taxRates.find(ct => ct.label === label)
        if (found) {
            tax = found.rate
        }
    }
    return tax
}

export function getTaxValueByLabel(label, currentTaxRates) {
    let tax = 0
    for (let i = 0; i < currentTaxRates.length; i++) {
        if (currentTaxRates[i].taxRates[0].label === label) {
            tax = currentTaxRates[i].taxRates[0].rate
        }
    }
    return tax
}

export function calculateBasicOfItem(item, currentTaxRates) {
    let tax = taxValueForLabel(item, currentTaxRates)
    return (parseFloat(item.unitPrice) / (tax * 0.01 + 1)) *
        parseFloat(item.addedQuantity  || item.quantity)
}

export function calculateBasic(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        let tax = taxValueForLabel(items[i], currentTaxRates)
        sum += (parseFloat(items[i].unitPrice) / (tax * 0.01 + 1)) *
            parseFloat(items[i].addedQuantity || items[i].quantity)
    }
    return sum;
}

export function isArrayContainsTax(array, tax) {
    let index = -1;
    if (!array)
        return index;
    if (array.length === 0)
        return index;
    for (let i = 0; i < array.length; i++) {
        if (array[i].label === tax.label) {
            index = i;
            return index
        }
    }
    return index;
}

export function calculatePdv(items, currentTaxRates) {
    let sum = 0;
    for (let i = 0; i < items.length; i++) {
        sum += parseFloat(items[i].unitPrice) *
            parseFloat(items[i].addedQuantity || items[i].quantity) - calculateBasicOfItem(items[i], currentTaxRates)
    }
    return sum;
}

export function convertItemName(name) {
    const chars = {'č': 'c', 'Č': 'C', 'ć': 'c', 'Ć': 'c', 'đ': 'dj', 'Đ': 'Đ', 'š': 's', 'Š': 'S', 'ž': 'z', 'Ž': 'Z'}
    return name?.replace(/[čČćĆđĐšŠžŽ]/g, m => chars[m])
}

export function isArrayContainsTaxA4(arr, item) {
    let index = -1
    if (!arr)
        return index;
    if (arr.length === 0)
        return index;
    for (let i = 0; i < arr.length; i++) {
        for (let j = 0; j < arr[i].label.length; j++) {
            for (let k = 0; k < item.labels.length; k++) {
                if (arr[i].label[j] === item.labels[k]) {
                    return i
                }
            }
        }
    }
    return index;
}

export const findRoleName = (role) => {
    switch (role){
        case 0:
            return "Administrator"
        case 1:
            return "Menadžer"
        case 2:
            return "Korisnik"
        case 3:
            return "Knjigovođa"
        case 8:
            return "Kuvar"
        case 9:
            return "Konobar"
        case 10:
            return "Šanker"
        default:
            return "Ostalo"
    }
}

export function isDatesEquals(date1, date2) {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export function isMonthEquals(date1, date2) {
    return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
}

export function formatDateFromSDC(sdc) {
    let date = sdc?.substring(0, 19)
    let dateT = date?.replace('T', ' ')
    return new Date(dateT)
}

export function getDateBefore(date) {
    return new Date(date.getTime() - 24 * 60 * 60 * 1000);
}