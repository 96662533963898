import { getSessionOperator } from "../../session";
import { collection, doc, getDocs, query, setDoc, Timestamp, where } from "firebase/firestore";
import { AUTH, DB } from "../../../auth/FirebaseContext";

export async function addRepresentationOnFirestore(tableUid, orders, note) {
    let cashier = getSessionOperator().username
    let id = Date.now()
    for (let order of orders) {
        Object.keys(order).forEach(key => {
            if (order[key] === undefined) {
                order = {
                    ...order,
                    key: "",
                }
            }
        })
        let newItems = [];
        for (let item of order.items) {
            // eslint-disable-next-line no-loop-func
            Object.keys(item).forEach(key => {
                if (order[key] === undefined) {
                    item = {
                        ...item,
                        key: "",
                    }
                }
            })
            newItems.push(item)
        }
        order = {
            ...order,
            items: newItems
        }
    }
    return await setDoc(doc(DB, 'invoices', "representations", AUTH.currentUser.uid, id.toString()), {
        tableUid: tableUid || null,
        orders: orders,
        cashier,
        note: note || null,
        time: Timestamp.fromMillis(id),
    })
}

export async function getRepresentationsFromFirestoreForDate(startDate, endDate) {
    let arr = []
    const q = await query(collection(DB, `invoices/representations/${AUTH.currentUser.uid}`),
        where("time", ">=", Timestamp.fromDate(startDate)),
        where("time", "<=", Timestamp.fromDate(endDate)));
    const querySnapshot = await getDocs(q);
    await querySnapshot.forEach((doc) => {
        arr.push({
            ...doc.data(),
            uid: doc.id,
        })
    })
    return arr;
}