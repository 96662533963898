import { collection, onSnapshot, query } from "firebase/firestore";
import { AUTH, DB } from "../../../auth/FirebaseContext";
import { ORDER_TYPE } from "../../../constants";
import { dispatch } from "../../../redux/store";
import { addAlertToTable, fetchAllOrders } from "../../../redux/slices/restaurant";
import { addOrderOffline, deleteOrderOffline, updateOrderOffline } from "../../../store/offlineDb";
import { addActiveOrderChefPage } from "../../../redux/slices/chef";

const parseItemsForFb = (items) => {
  return items.map(item => ({
    uid: item.uid,
    quantity: item.quantity,
    addedQuantity: item.quantity,
    price: item.price,
    name: item.name,
    note: item.note || null,
    prepTime: item.prepTime !== 0 ? item.prepTime : item.prepTime,
    restaurantGroup: item?.restaurantGroup || false,
    vat: item.vat,
    unit: item.unit
  }));
};

export function addRestaurantListener(isWaiterIndexPage, orderType) {
  const q = query(collection(DB, `orders/users/${AUTH.currentUser.uid}`));
  onSnapshot(q, async (snapshot) => {
    for (const change of snapshot.docChanges()) {
      let order = change.doc.data();
      let parsedOrder = {
        id: parseInt(change.doc.id),
        active: order.active,
        alert: order.alert || null,
        finishedPreparation: order.finishedPreparation,
        items: parseItemsForFb(order.items),
        isMobile: order.isMobile,
        layoutUid: order.layoutUid,
        submitted: true,
        tableUid: order.tableUid,
        orderType: order.orderType || ORDER_TYPE.all,
        cashier: order.cashier,
        userUid: AUTH.currentUser.uid
      };

      if (change.type === "added") {
        await addOrderOffline(parsedOrder);
        if (isWaiterIndexPage) {
          dispatch(fetchAllOrders());
        }
      }
      if (change.type === "modified") {
        await updateOrderOffline(parsedOrder);
        if (parsedOrder.alert) {
          dispatch(addAlertToTable({
            tableUid: parsedOrder.tableUid,
            alert: parsedOrder.alert
          }));
        }
        if (isWaiterIndexPage) {
          dispatch(fetchAllOrders());
        }
      }
      if (change.type === "removed") {
        await deleteOrderOffline(parsedOrder.id);
        if (isWaiterIndexPage) {
          dispatch(fetchAllOrders());
        }
      }
      dispatch(addActiveOrderChefPage({
        order: parsedOrder,
        type: change.type,
        orderType: orderType
      }));
    }
  });
}