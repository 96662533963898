import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import { child, get, push, update } from "firebase/database";
import { doc, getDoc } from "firebase/firestore";
import { getDownloadURL, ref } from "firebase/storage";
import { AUTH, DB, dbRef, storageRef } from "../../auth/FirebaseContext";
import {
  addLayoutOffline,
  cancelOrderOffline,
  getAllActiveOrdersByTableIdOffline,
  getAllLayoutsOffline,
  getAllOfflineOrders,
  transferOrderToAnotherTableOffline
} from "../../store/offlineDb";
import { fetchBlobFromImgUrl } from "../../utils/other";
import {
  addDeletedOnlineOrder,
  deleteOnlineOrder,
  transferOrderToAnotherTableFirestore,
  updateActive
} from "../../helper/firestore/restaurant";
import { OPERATORS_GROUP, TABLE_COLORS } from "../../constants";

export const insertRestaurantTables = createAsyncThunk("insertRestaurantTables", async (payload) => {
  return await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/layouts`), payload);
});

export const fetchAllOrders = createAsyncThunk("fetchAllOrders", async () => {
  return getAllOfflineOrders();
});

export const colorAllTables = createAsyncThunk("colorAllTables", async (_, { getState }) => {
  const state = getState();
  const { tables } = state.restaurants;
  let newTables = [...tables];
  const allOrders = await getAllOfflineOrders();
  for (let i = 0; i < newTables.length; i++) {
    let have = allOrders.some(order => order.tableUid === newTables[i].uid);
    if (have) {
      newTables[i] = {
        ...newTables[i],
        preFillColor: TABLE_COLORS.free
      };
    }
  }
  return newTables;
});

export const updateRestaurantTableData = createAsyncThunk("updateRestaurantTableData", async (payload) => {
  const { layout, index, name } = payload;
  let tables = [...layout.tables];
  let obj = { ...tables[index] };
  obj.name = name;
  tables[index] = obj;
  await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/layouts/${layout.uid}/tables/${index}`), obj);
  return tables;
});

export const getRestaurantTableName = createAsyncThunk("getRestaurantTableName", async (payload) => {
  const { layout, index } = payload;
  return await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/layouts/${layout.uid}/tables/${index}`));
});

export const fetchRestaurantData = createAsyncThunk("fetchRestaurantData", async () => {
  let offline = await getAllLayoutsOffline();
  let layouts = [];
  let tables = [];
  let i = 0;
  if (offline.length === 0) {
    let arr = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/layouts`))).forEach(child => {
      arr.push({
        ...child.val(),
        uid: child.key
      });
      if (i === 0) {
        Object.values(child.val().tables).forEach(value => {
          tables.push(value);
        });
      }
      i = i + 1;
    });

    for (const layout of arr) {
      const imageRef = ref(ref(storageRef, "public/restaurantsLayout"), `${layout.image}`);
      let url = await getDownloadURL(imageRef);
      let blobImage = await fetchBlobFromImgUrl(url);
      layouts.push({
        ...layout,
        image: url,
        blobImage: blobImage
      });
      await addLayoutOffline({
        uid: layout.uid,
        name: layout.name,
        userUid: AUTH.currentUser.uid,
        image: url,
        blobImage: blobImage,
        operators: layout.operators,
        tables: layout.tables
      });
    }
  } else {
    for (const layout of offline) {
      layouts.push({
        uid: layout.uid,
        name: layout.name,
        image: layout.image,
        operators: layout.operators,
        tables: layout.tables
      });
      if (i === 0) {
        Object.values(layout.tables).forEach(value => {
          tables.push(value);
        });
      }
      i = i + 1;
    }
  }
  return {
    tables: tables,
    layouts: layouts
  };
});

export const transferOrders = createAsyncThunk("transferOrders", async (data) => {
  const { tableUid, orders } = data;
  for (const order of orders) {
    await transferOrderToAnotherTableFirestore(order, tableUid);
    await transferOrderToAnotherTableOffline(order, tableUid);
  }
});

export const checkOrdersForTableAndChangeColor = createAsyncThunk("checkOrdersForTableAndChangeColor", async (data, { getState }) => {
  const { tableFrom, tableTo } = data;
  const state = getState();
  let arr = [...state.restaurants.tables];
  let ordersFrom = await getAllActiveOrdersByTableIdOffline(tableFrom);
  let indexFrom = arr.findIndex(item => item.uid === tableFrom);
  let indexTo = arr.findIndex(item => item.uid === tableTo);
  let border = null;
  if (ordersFrom.length > 0) {
    if (indexTo !== -1) {
      border = arr[indexFrom].borderColor;
      arr[indexFrom] = {
        ...arr[indexFrom],
        preFillColor: TABLE_COLORS.taken
      };
    }
  } else {
    if (indexTo !== -1) {
      border = arr[indexFrom].borderColor;
      arr[indexFrom] = {
        ...arr[indexFrom],
        preFillColor: TABLE_COLORS.free,
        borderColor: null
      };
    }
  }
  if (indexTo !== -1) {
    arr[indexTo] = {
      ...arr[indexTo],
      preFillColor: TABLE_COLORS.taken,
      borderColor: border
    };
  }
  return arr;
});

export const cancelOrder = createAsyncThunk("cancelOrder", async (order) => {
  const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${order.id}`);
  const onlineOrder = (await getDoc(docRef)).data();
  if (!onlineOrder) {
    throw new Error("Order not exist");
  }
  if (onlineOrder.finishedPreparation) {
    throw new Error("Order is already prepared");
  }
  let operators = (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/operators`))).val();
  let isHaveChefOrBarmen = Object.values(operators).some(op => parseInt(op.group) === OPERATORS_GROUP.bar ||
    parseInt(op.group) === OPERATORS_GROUP.chef);
  // TODO ZASTO SE KORISTI UPDATE ACTIVE
  if (!isHaveChefOrBarmen) {
    await deleteOnlineOrder(order);
    await addDeletedOnlineOrder(order);
    return;
  }
  await addDeletedOnlineOrder(order);
  await updateActive(order.id, false);
  await cancelOrderOffline(order.id)
});


const initialState = {
  layouts: [],
  tables: [],
  orders: [],
  loading: true,
  selectedLayout: 0,
  updating: false
};
export const slice = createSlice({
  name: "restaurants",
  initialState,
  reducers: {
    changeRestaurantLayout: (state, { payload }) => {
      if (payload !== state.selectedLayout) {
        state.loading = true;
        state.selectedLayout = payload;
        let arr = [...current(state.layouts)];
        let tables = [];
        for (let i = 0; i < arr.length; i++) {
          if (i === payload) {
            Object.values(arr[i].tables).forEach(value => {
              tables.push(value);
            });
          }
        }
        state.tables = tables;
        state.loading = false;
      }
    },
    changeTableColor: (state, { payload }) => {
      const { tableUid, color, borderColor, alert } = payload;
      let arr = [...current(state.tables)];
      let index = arr.findIndex(item => item.uid === tableUid);
      if (index !== -1) {
        arr[index] = {
          ...arr[index],
          preFillColor: color,
          borderColor: borderColor,
          alert: alert
        };
      }
      state.tables = arr;
    },
    addAlertToTable: (state, { payload }) => {
      const { tableUid, alert, borderColor } = payload;
      let arr = [...current(state.tables)];
      let index = arr.findIndex(item => item.uid === tableUid);
      if (index !== -1) {
        arr[index] = {
          ...arr[index],
          alert: alert,
          borderColor: borderColor
        };
      }
      state.tables = arr;
    }
  },
  extraReducers: {
    //fetchRestaurantData
    [fetchRestaurantData.pending]: (state) => {
      state.loading = true;
    },
    [fetchRestaurantData.fulfilled]: (state, { payload }) => {
      const { tables, layouts } = payload;
      state.tables = tables;
      state.layouts = layouts;
      state.loading = false;
    },
    [fetchRestaurantData.rejected]: (state, _) => {
      state.loading = false;
    },
    //transferOrders
    [transferOrders.pending]: (state) => {
      state.updating = true;
    },
    [transferOrders.fulfilled]: (state) => {
      state.updating = false;
    },
    [transferOrders.rejected]: (state, _) => {
      state.updating = false;
    },
    //checkOrdersForTableAndChangeColor
    [checkOrdersForTableAndChangeColor.pending]: (state) => {
      state.loading = true;
    },
    [checkOrdersForTableAndChangeColor.fulfilled]: (state, { payload }) => {
      state.tables = payload;
      state.loading = false;
    },
    [checkOrdersForTableAndChangeColor.rejected]: (state) => {
      state.loading = false;
    },

    [insertRestaurantTables.pending]: (state) => {
      state.loading = true;
    },
    [insertRestaurantTables.fulfilled]: (state) => {
      state.loading = false;
    },
    [insertRestaurantTables.rejected]: (state, _) => {
      state.loading = false;
    },

    [updateRestaurantTableData.pending]: (state) => {
      state.loading = true;
    },
    [updateRestaurantTableData.fulfilled]: (state, { payload }) => {
      state.tables = payload;
      state.loading = false;
    },
    [updateRestaurantTableData.rejected]: (state) => {
      state.loading = false;
    },
    //fetchAllOrders
    [fetchAllOrders.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllOrders.fulfilled]: (state, { payload }) => {
      state.orders = payload;
      state.loading = false;
    },
    [fetchAllOrders.rejected]: (state) => {
      state.loading = false;
    },
    [colorAllTables.fulfilled]: (state, {payload}) => {
      state.tables = payload;
    }
  }
});
export const {
  changeTableColor, addAlertToTable,
  changeRestaurantLayout
} = slice.actions;
export default slice.reducer;
