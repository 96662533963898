import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getAllWrittenOffProductsFromFirestore } from "../../helper/firestore/writeOff";

export const fetchAllWrittenOffItems = createAsyncThunk("fetchAllWrittenOffItems", async () => {
  return getAllWrittenOffProductsFromFirestore();
});

const initialState = {
  writeOffList: [],
  loading: false
};

const slice = createSlice({
  name: "writeOff",
  initialState,
  extraReducers: {
    // fetchAllIngredients
    [fetchAllWrittenOffItems.pending]: (state) => {
      state.loading = true;
    },
    [fetchAllWrittenOffItems.fulfilled]: (state, { payload }) => {
      state.writeOffList = payload;
      state.loading = false;
    },
    [fetchAllWrittenOffItems.rejected]: (state) => {
      state.loading = false;
    }
  }
});

// Reducer
export default slice.reducer;