import { AUTH, DB } from "../../../auth/FirebaseContext";
import { getSessionOperator } from "../../session";
import { getProductFromRealtimeDbByUid } from "../../realtimeDatabase";
import { collection, doc, getDocs, query, setDoc, Timestamp, where } from "firebase/firestore";
import { putLogToFirebase } from "../../../store/offlineDb";

export async function addWriteOffToFirestore(orderId, productId, quantity) {
  let cashier = getSessionOperator().username
  let id = Date.now()
  let product = await getProductFromRealtimeDbByUid(productId);
  setDoc(doc(DB, 'writeOff', "users", AUTH.currentUser.uid, id.toString()), {
    orderId: orderId || null,
    product: {
      ...product,
      uid: productId,
    },
    quantity: quantity,
    cashier,
    // note: note || null,
    writeOfTime: Timestamp.fromMillis(id),
  }).then(_ => {
    console.debug("Uspesno sacuvan writeOff na firestoru");
  }).catch(e => {
    console.error("addWriteOffToFirestore", e)
    putLogToFirebase("addWriteOffToFirestore", "firestore/writeOff/index.js",
      [orderId, productId, quantity], e.toString())
  })
}

export async function getAllWrittenOffProductsFromFirestore() {
  let arr = []
  const q = await query(collection(DB, `writeOff/users/${AUTH.currentUser.uid}`));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      uid: doc.id,
    })
  })
  return arr;
}

export async function getAllWrittenOffProductsFromFirestoreForDate(startDate, endDate) {
  let arr = []
  const q = await query(collection(DB, `writeOff/users/${AUTH.currentUser.uid}`),
    where("writeOfTime", ">=", Timestamp.fromDate(startDate)),
    where("writeOfTime", "<=", Timestamp.fromDate(endDate)));
  const querySnapshot = await getDocs(q);
  await querySnapshot.forEach((doc) => {
    arr.push({
      ...doc.data(),
      uid: doc.id,
    })
  })
  return arr;
}