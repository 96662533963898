import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";
import {
    getAllOfflineSubmittedOrders,
    getAllOfflineSubmittedOrdersForBar,
    updateOrderOffline
} from "../../store/offlineDb";
import { updateFinishPreparation, updateOrderTypeAndFinishPreparation } from "../../helper/firestore/restaurant";
import { ORDER_TYPE } from "../../constants";

export const fetchActiveOrdersForChefPage = createAsyncThunk("fetchActiveOrdersForChefPage", async () => {
    return await getAllOfflineSubmittedOrders(false)
})

export const fetchActiveOrdersForBarPage = createAsyncThunk("fetchActiveOrdersForBarPage", async () => {
    return await getAllOfflineSubmittedOrdersForBar(false)
})

export const submitOrderPreparationForChefPage = createAsyncThunk("submitOrderPreparationForChefPage", async (data) => {
    if (data) {
        let updated = await updateOrderOffline({
            ...data,
            finishedPreparation: true
        })
        if (updated === 1) {
            await updateFinishPreparation(data.id, true)
        }
    } else {
        throw Error();
    }
})

export const submitOrderPreparationForBarPage = createAsyncThunk("submitOrderPreparationForBarPage", async (data) => {
    if (data) {
        let finishedPreparation = true;
        let orderType = data.orderType;
        // if (orderType === ORDER_TYPE.all) {
        //     orderType = ORDER_TYPE.kitchen
        //     finishedPreparation = false
        // } else if (orderType === ORDER_TYPE.bar) {
        //     finishedPreparation = true;
        // }
        let updated = await updateOrderOffline({
            ...data,
            finishedPreparation: true,
            orderType: orderType
        })
        if (updated === 1) {
            await updateOrderTypeAndFinishPreparation(data.id, orderType, finishedPreparation)
        }
    } else {
        throw Error();
    }
})

const initialState = {
    activeOrders: [],
    confirmDialogProps: {
        open: false,
        data: undefined
    },
    loading: false,
}

export const chefPageSlice = createSlice({
    name: 'chefPage',
    initialState,
    reducers: {
        addActiveOrderChefPage: (state, {payload}) => {
            const {order, type, orderType} = payload;
            if (order.orderType === ORDER_TYPE.all || order.orderType === orderType) {
                let arr = [...current(state.activeOrders)]
                let index = arr.findIndex(localOrder => localOrder.id === order.id);
                switch (type) {
                    case "added":
                        if (index === -1 && order.finishedPreparation === false) {
                            arr.push(order)
                        }
                        break;
                    case "modified":
                        if (index !== -1) {
                            arr[index] = order;
                        } else {
                            if (order.finishedPreparation === false) {
                                arr.push(order)
                            }
                        }
                        break;
                    case "removed":
                        if (index !== -1 && order.finishedPreparation === false) {
                            arr.splice(index, 1)
                        }
                        break;
                    default:
                        console.error("NEPOZNATA AKCIJA", type);
                        break;
                }
                state.activeOrders = arr;
            }
        },
        handleOpenConfirmDialog: (state, {payload}) => {
            state.confirmDialogProps = {
                open: true,
                data: payload
            }
        },
        handleCloseConfirmDialog: (state) => {
            state.confirmDialogProps = {
                open: false,
                data: undefined
            }
        },
    },
    extraReducers: {
        //fetchActiveOrdersForChefPage
        [fetchActiveOrdersForChefPage.pending]: (state) => {
            state.loading = true
        },
        [fetchActiveOrdersForChefPage.fulfilled]: (state, {payload}) => {
            state.activeOrders = payload;
            state.loading = false
        },
        [fetchActiveOrdersForChefPage.rejected]: (state) => {
            state.loading = false
        },
        [fetchActiveOrdersForBarPage.pending]: (state) => {
            state.loading = true
        },
        [fetchActiveOrdersForBarPage.fulfilled]: (state, {payload}) => {
            console.log({payload})
            state.activeOrders = payload;
            state.loading = false
        },
        [fetchActiveOrdersForBarPage.rejected]: (state) => {
            state.loading = false
        },
        //submitOrderPreparationForChefPage
        [submitOrderPreparationForChefPage.pending]: (state) => {
            state.loading = true
        },
        [submitOrderPreparationForChefPage.fulfilled]: (state, {meta}) => {
            let arr = [...current(state.activeOrders)]
            let index = arr.findIndex(localOrder => localOrder.id === meta.arg.id);
            arr.splice(index, 1);
            state.activeOrders = arr;
            state.loading = false
        },
        [submitOrderPreparationForChefPage.rejected]: (state) => {
            state.loading = false
        },
        //submitOrderPreparationForBarPage
        [submitOrderPreparationForBarPage.pending]: (state) => {
            state.loading = true
        },
        [submitOrderPreparationForBarPage.fulfilled]: (state, {meta}) => {
            let arr = [...current(state.activeOrders)]
            let index = arr.findIndex(localOrder => localOrder.id === meta.arg.id);
            arr.splice(index, 1);
            state.activeOrders = arr;
            state.loading = false
        },
        [submitOrderPreparationForBarPage.rejected]: (state) => {
            state.loading = false
        },
    }
});
export const {addActiveOrderChefPage, handleOpenConfirmDialog, handleCloseConfirmDialog} = chefPageSlice.actions
export const chefPageReducer = chefPageSlice.reducer;
