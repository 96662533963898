import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { get50RecordsOnRealTimeDB, itemsAveragePurchasePrice, itemsStateOnDate } from "../../helper/realtimeDatabase";
import { TRANSACTION_TYPE } from "../../constants";
import moment from "moment";
import { getAllOfflineInvoiceForReportBySdcDateTime } from "../../store/offlineDb";
import { child, get, ref } from "firebase/database";
import { AUTH, dbRef, RDB } from "../../auth/FirebaseContext";
import { getAllFacturesByDateFromFirestore } from "../../helper/firestore/factures";

// TODO kopirano, ako moze optimizacija
export const fetchStockList = createAsyncThunk("fetchStockList", async (data, { getState }) => {
  const state = getState();
  const { currentTaxRates } = state.taxCore;
  const { chosenDate } = data;
  const userItemsDbRef = ref(RDB, `users/${AUTH.currentUser.uid}/private/items`);
  let items = [];
  let itemsWithIng = {};
  let lastData = "";
  await get50RecordsOnRealTimeDB(userItemsDbRef, lastData, []).then(async value => {
    for (const item of value) {
      let publicItem = (await get(child(dbRef, `public/items/${item.uid}`))).val();
      let arr = Object.values(item.oldPrices || {});
      let taxRate = currentTaxRates.find(tax => tax.taxRates[0].label === item.vat);
      let newItem = {
        ...publicItem,
        ...item,
        taxRate: taxRate ? taxRate.taxRates[0].rate : 0,
        price: arr.length > 0 ?
          ((arr.reduce((a, { price }) => a + Number(price), 0) + item.price) / Number(arr.length + 1)) : item.price
      };
      if (item.ingredients !== undefined) {
        itemsWithIng[item.uid] = item.ingredients;
      } else {
        items.push(newItem);
      }
    }
  });
  (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients`))).forEach((child1) => {
    let taxRate = currentTaxRates.find(tax => tax.taxRates[0].label === child1.val().vat);
    items.push({
      ...child1.val(),
      uid: child1.key,
      taxRate: taxRate ? taxRate.taxRates[0].rate : 0
    });
  });
  //entryQuantity
  let factures = await getAllFacturesByDateFromFirestore(
    moment(chosenDate).set("hour", 0).set("minute", 0).set("second", 0).toDate(),
    moment(chosenDate).set("hour", 23).set("minute", 59).set("second", 59).toDate());
  for (let i = 0; i < items.length; i++) {
    for (const facture of factures) {
      for (const fItem of facture.items) {
        if (fItem.uid === items[i].uid) {
          if (items[i].entryQuantity) {
            items[i].entryQuantity = items[i].entryQuantity + Number(fItem.quantity);
          } else {
            items[i].entryQuantity = Number(fItem.quantity);
          }
        }
      }
    }
  }
  //exitQuantity
  let allInvoices = await getAllOfflineInvoiceForReportBySdcDateTime(
    moment(chosenDate).set("hour", 0).set("minute", 0).set("second", 0).format("YYYY-MM-DDTHH:mm:ss"),
    moment(chosenDate).set("hour", 23).set("minute", 59).set("second", 59).format("YYYY-MM-DDTHH:mm:ss"),
    undefined);
  for (let i = 0; i < items.length; i++) {
    for (const invoice of allInvoices) {
      for (const invItem of invoice.items) {
        if (invItem.uid) {
          if (invItem.uid === items[i].uid) {
            if (items[i].exitQuantity) {
              if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                items[i].exitQuantity = items[i].exitQuantity + Number(invItem.quantity);
              } else {
                items[i].exitQuantity = items[i].exitQuantity - Number(invItem.quantity);
              }
            } else {
              if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                items[i].exitQuantity = Number(invItem.quantity);
              } else {
                items[i].exitQuantity = -Number(invItem.quantity);
              }
            }
          }
        }
      }
    }
  }
  for (const invoice of allInvoices) {
    for (const invItem of invoice.items) {
      if (invItem.uid) {
        if (itemsWithIng[invItem.uid] !== undefined) {
          for (const el of Object.keys(itemsWithIng[invItem.uid])) {
            const index = items.findIndex(lItem => lItem.uid === el);
            if (index !== -1) {
              const forMake = itemsWithIng[invItem.uid][el].quantity;
              if (items[index].exitQuantity) {
                if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                  items[index].exitQuantity = items[index].exitQuantity + (Number(invItem.quantity) * Number(forMake));
                } else {
                  items[index].exitQuantity = items[index].exitQuantity - (Number(invItem.quantity) * Number(forMake));
                }
              } else {
                if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                  items[index].exitQuantity = (Number(invItem.quantity) * Number(forMake));
                } else {
                  items[index].exitQuantity = -(Number(invItem.quantity) * Number(forMake));
                }
              }
            }
          }
        }
      }
    }
  }
  //currentItemsState
  let currentItemsState = await itemsStateOnDate(chosenDate);
  //average purache price
  let avgItemsPrice = await itemsAveragePurchasePrice(chosenDate);
  items = items.map(item => {
    let exitQuantity = item.exitQuantity || 0;
    let entryQuantity = item.entryQuantity || 0;
    let startQuantity = currentItemsState[item.uid] || 0;
    let avgPurchasePrice = avgItemsPrice[item.uid] || 0;
    let avgPurchasePriceWithoutTax = avgPurchasePrice / (item.taxRate * 0.01 + 1);
    let restQuantity = (Number(startQuantity) + Number(entryQuantity)) - Number(exitQuantity);
    return {
      ...item,
      id: item.uid,
      exitQuantity: exitQuantity,
      entryQuantity: entryQuantity,
      startQuantity: startQuantity,
      restQuantity: restQuantity,
      avgPurchasePrice: avgPurchasePrice,
      avgPurchasePriceWithoutTax: avgPurchasePriceWithoutTax,
      priceWithoutTax: item.price / (item.taxRate * 0.01 + 1),
      exitValueWithoutTax: exitQuantity * avgPurchasePriceWithoutTax,
      exitValueWithTax: exitQuantity * avgPurchasePrice,
      nabVreSaPdv: Number(avgPurchasePrice * restQuantity).toFixed(2),
      nabVreBezPdv: Number(avgPurchasePriceWithoutTax * restQuantity).toFixed(2),
      prodVreBezPdv: Number((item.price / (item.taxRate * 0.01 + 1)) * restQuantity).toFixed(2),
      prodVreSaPdv: Number(item.price * restQuantity).toFixed(2)
    };
  });
  return items;
});

const initialState = {
  stockList: [],
  loading: false
};

const slice = createSlice({
  name: "stockList",
  initialState,
  extraReducers: {
    [fetchStockList.pending]: (state) => {
      state.loading = true;
    },
    [fetchStockList.fulfilled]: (state, { payload }) => {
      state.stockList = payload;
      state.loading = false;
    },
    [fetchStockList.rejected]: (state, action) => {
      console.error("a", action);
      state.loading = false;
    }
  }
});

// Reducer
export default slice.reducer;