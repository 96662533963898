import {combineReducers} from "redux";
import storage from "redux-persist/lib/storage";
// slices
import userReducer from "./slices/user";
import groupsPublicReducer from "./slices/groupsPublic";
import {operatorsReducer} from "./slices/operators";
import suppliersReducer from "./slices/suppliers";
import inventoryReducer from "./slices/inventory";
import stockListReducer from "./slices/stockList";
import ingredientsReducer from "./slices/ingredients";
import taxCoreReducer from "./slices/taxCore";
import notificationsReducer from "./slices/notifications";
import facturesReducer from "./slices/factures";
import reservationsReducer from "./slices/reservations";
import writeOffReducer from "./slices/writeOff";
import reportsReducer from "./slices/reports";
import settingsReducer from "./slices/settings";
import locationsReducer from "./slices/locations";
import esirInvoicesReducer from "./slices/esirInvoices";
import restaurantsReducer from "./slices/restaurant";
import synchronizationsReducer from "./slices/synchronization";
import {itemsReducer} from "./slices/items";
import {vatReducer} from "./slices/vats";
import {discountsReducer} from "./slices/discounts";
import {licenseReducer} from "./slices/license";
import {customersReducer} from "./slices/customers";
import {costsReducer} from "./slices/costs";
import {discountCustomerReducer} from "./slices/customersDiscounts";
import {chefPageReducer} from "./slices/chef";
// ----------------------------------------------------------------------

const rootPersistConfig = {
    key: "root",
    storage,
    keyPrefix: "redux-",
    whitelist: []
};

const rootReducer = combineReducers({
    settings: settingsReducer,
    operators: operatorsReducer,
    locations: locationsReducer,
    stockList: stockListReducer,
    ingredients: ingredientsReducer,
    taxCore: taxCoreReducer,
    suppliers: suppliersReducer,
    writeOff: writeOffReducer,
    inventory: inventoryReducer,
    notifications: notificationsReducer,
    factures: facturesReducer,
    reservations: reservationsReducer,
    reports: reportsReducer,
    restaurants: restaurantsReducer,
    synchronizations: synchronizationsReducer,
    groupsPublic: groupsPublicReducer,
    esirInvoices: esirInvoicesReducer,
    customers: customersReducer,
    costs: costsReducer,
    user: userReducer,
    items: itemsReducer,
    discounts: discountsReducer,
    license: licenseReducer,
    vats: vatReducer,
    discountCustomers: discountCustomerReducer,
    chef: chefPageReducer

});

export {rootPersistConfig, rootReducer};
