import { AUTH } from "../auth/FirebaseContext";
import { getSessionSelectedLocation } from "../helper/session";
import axios from "axios";
import { getStatusLpfr } from "./lpfr";


const BASE_URL = "https://europe-central2-esir-44ade.cloudfunctions.net/taxCoreAPI";

const customAxios = (token, pac) => axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "Authorization": `Bearer ${token}`,
    "PAC": pac
  },
  withCredentials: false
});


export async function getTaxCoreStatus(isAlreadyPosted = false) {
  return new Promise(async (resolve, reject) => {
    await getStatusFirebaseVpfr().then(responseVpfr => {
      return resolve({
        response: responseVpfr.data,
        type: "vpfr"
      });
    }).catch(async errorVpfr => {
      if (!isAlreadyPosted) {
        await loginOnError403(errorVpfr).then(_ => {
          getTaxCoreStatus(true).then(r => {
            return resolve({
              response: r.response,
              type: "vpfr"
            });
          });
        });
      } else {
        await getStatusLpfr().then(responsLpfr => {
          return resolve({
            response: responsLpfr.data,
            type: "lpfr"
          });
        }).catch(reason => {
          return reject(reason);
        });
      }
    });
  });
}

async function getStatusFirebaseVpfr() {
  const token = await AUTH.currentUser.getIdToken(true);
  const pac = getSessionSelectedLocation().PAC;
  return customAxios(token, pac).get("/api/v3/status");
}

export async function loginOnError403(e) {

  if (e?.response?.status === 403) {
    try {
      // const bytes = CryptoJS.AES.decrypt(getLoggedUser(), 'esiresir');
      // const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      //await signInWithEmailAndPassword(AUTH, AUTH.currentUser.email, AUTH.password);
    } catch {
      console.error("nije proslo logovanje");
    }
  }
}