import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSessionOperator } from "../../helper/session";
import {
    getAllOfflineInvoiceByTableUid,
    getAllOfflineInvoiceForReportByItemNameOrGroupName,
    getAllOfflineInvoiceForReportBySdcDateTime,
    getChargeFreeInvoiceFromOfflineDB
} from "../../store/offlineDb";
import { REPORTS_ROLES, TRANSACTION_TYPE } from "../../constants";
import moment from "moment";
import { getAllWrittenOffProductsFromFirestoreForDate } from "../../helper/firestore/writeOff";
import {
    getAllFacturesByDateFromFirestore,
    getAllFacturesByEndDateFromFirestore
} from "../../helper/firestore/factures";
import { calculateBasic, calculatePdv, convertItemName, getTaxValueByLabel } from "../../helper/other";
import {
    getCostsFromRealtimeDbByUid,
    getIngredientFromRealtimeDbByUid,
    getProductFromRealtimeDbByUid,
    itemsStateOnDate
} from "../../helper/realtimeDatabase";
import {
    addCashiersAmountFromInvoiceForProductToArray,
    addCashiersAmountFromInvoiceToArray,
    addItemFromInvoiceToArray,
    addItemFromInvoiceToArrayByTax,
    addPaymentMethodFromInvoiceToArray,
    addRefundTaxItemsFromInvoiceToArray,
    addTaxItemsFromInvoiceToArray,
    concatItemsForTax
} from "../../helper/report";
import { getRepresentationsFromFirestoreForDate } from "../../helper/firestore/representation";

const handleCalculateProfit = (invoiceType, itemPrice, averagePrice, quantity) => {
    return invoiceType === TRANSACTION_TYPE.sale ? (itemPrice - averagePrice) * quantity :
        -(itemPrice - averagePrice) * quantity;
};

export const fetchProfitReport = createAsyncThunk("fetchProfitReport", async (data) => {
    const {startDate, endDate, location, currentTaxRates} = data;
    let allInvoices = await getAllOfflineInvoiceForReportBySdcDateTime(
        startDate.format("YYYY-MM-DDTHH:mm:ss"), endDate.format("YYYY-MM-DDTHH:mm:ss"), location);
    let factures = await getAllFacturesByEndDateFromFirestore(endDate.toDate());
    let factureItems = [];
    for (const facture of factures) {
        for (const item of facture.items) {
            let index = factureItems.findIndex(obj => obj.uid === item.uid);
            if (index === -1) {
                factureItems.push({
                    ...item,
                    averagePrice: item.purchasePrice
                });
            } else {
                factureItems[index] = {
                    ...factureItems[index],
                    averagePrice: (factureItems[index].averagePrice + item.purchasePrice) / 2
                };
            }
        }
    }
    let finalArr = [];
    for (const invoice of allInvoices) {
        for (const item of invoice.items) {
            let index = finalArr.findIndex(obj => obj.uid === item.uid);
            let indexOfFactureItem = factureItems.findIndex(obj => obj.uid === item.uid);
            if (indexOfFactureItem !== -1) {
                const basicUnitPrice = calculateBasic([{
                    ...item,
                    vat: item.labels[0]
                }], currentTaxRates);
                const basicPurchasePrice = calculateBasic([{
                    unitPrice: factureItems[indexOfFactureItem].averagePrice,
                    quantity: item.quantity,
                    vat: item.labels[0]
                }], currentTaxRates);
                if (index === -1) {
                    finalArr.push({
                        name: item.name,
                        uid: item.uid,
                        vatValue: getTaxValueByLabel(item.labels[0], currentTaxRates),
                        vat: item.labels[0],
                        purchasePrice: factureItems[indexOfFactureItem].averagePrice,
                        basicPurchasePrice: basicPurchasePrice,
                        unitPrice: item.unitPrice,
                        basicUnitPrice: basicUnitPrice,
                        quantity: item.quantity,
                        restaurantGroup: item.restaurantGroup,
                        restaurantItemsCategory: item.restaurantItemsCategory,
                        profit: handleCalculateProfit(invoice.transactionType, item.unitPrice,
                            factureItems[indexOfFactureItem].averagePrice, item.quantity)
                    });
                } else {
                    finalArr[index] = {
                        ...finalArr[index],
                        profit: finalArr[index].profit + handleCalculateProfit(invoice.transactionType, item.unitPrice,
                            factureItems[indexOfFactureItem].averagePrice, item.quantity),
                        quantity: finalArr[index].quantity + item.quantity
                    };
                }
            }
        }
    }
    return finalArr.map(value => ({
        ...value,
        basicProfit: (value.basicUnitPrice * value.quantity) - (value.basicPurchasePrice * value.quantity)
    }));
});

export const fetchCostsReport = createAsyncThunk("fetchCostsReport", async (data) => {
    const {startDate, endDate} = data;
    let factures = await getAllFacturesByDateFromFirestore(startDate.toDate(), endDate.toDate());
    let costsArr = [];
    for (const facture of factures) {
        if (facture.costs) {
            let paid = 0;
            if (facture.payments) {
                paid = facture.payments.reduce((agg, {amount}) => agg + amount, 0);
            }
            paid = Number(Number(paid).toFixed(2));
            let totalAmount = facture?.costs.reduce((aux, {purchasePrice, quantity, discount}) => {
                return aux + ((Number(purchasePrice) * Number(quantity)) -
                    ((Number(purchasePrice) * Number(quantity)) * (Number(discount) / 100)));
            }, 0);
            totalAmount = Number(Number(totalAmount).toFixed(2));
            for (const cost of facture.costs) {
                let index = costsArr.findIndex(localItem => localItem.uid === cost.uid);
                let isPaid = paid >= totalAmount;
                let costPrice = (Number(cost.purchasePrice) * Number(cost.quantity)) -
                    ((Number(cost.purchasePrice) * Number(cost.quantity)) * (Number(cost.discount) / 100));
                costPrice = Number(Number(costPrice).toFixed(2));
                if (index === -1) {
                    let costObj = await getCostsFromRealtimeDbByUid(cost.uid);
                    costsArr.push({
                        ...costObj,
                        costUid: cost.costUid,
                        forPay: isPaid ? 0 : costPrice,
                        paid: isPaid ? costPrice : 0
                    });
                } else {
                    costsArr[index] = {
                        ...costsArr[index],
                        forPay: costsArr[index].forPay + (isPaid ? 0 : costPrice),
                        paid: costsArr[index].paid + (isPaid ? costPrice : 0)
                    };
                }
            }
        }
    }

    return costsArr;
});

export const fetchOverviewReport = createAsyncThunk("fetchOverviewReport", async (data) => {
    const {startDate, endDate, location, currentTaxRates} = data;
    let sessionGroup = getSessionOperator().group;
    let sessionName = convertItemName(getSessionOperator().username);
    let allInvoices = await getAllOfflineInvoiceForReportBySdcDateTime(
        startDate.format("YYYY-MM-DDTHH:mm:ss"), endDate.format("YYYY-MM-DDTHH:mm:ss"), location);
    let allCashiers = [];
    let allItems = [];
    let refundItems = [];
    let amountByTax = [];
    let paymentMethods = [];
    let taxItems = [];
    let taxRefundItems = [];
    let refundedSum = 0;
    let normalSum = 0;
    let numberOfSaleInvoices = 0;
    let numberOfRefundInvoices = 0;
    for (let invoice of allInvoices) {
        if (REPORTS_ROLES.includes(sessionGroup) || invoice.cashier === sessionName) {
            if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                normalSum = normalSum + parseFloat(invoice.totalAmount);
                numberOfSaleInvoices = numberOfSaleInvoices + 1;
                addItemFromInvoiceToArray(allItems, invoice, false, currentTaxRates);
                addTaxItemsFromInvoiceToArray(taxItems, invoice, false);
                addPaymentMethodFromInvoiceToArray(paymentMethods, invoice, false);
                addCashiersAmountFromInvoiceToArray(allCashiers, invoice, false);
            } else if (invoice.transactionType === TRANSACTION_TYPE.refund) {
                refundedSum = refundedSum + parseFloat(invoice.totalAmount);
                numberOfRefundInvoices = numberOfRefundInvoices + 1;
                addItemFromInvoiceToArray(refundItems, invoice, true, currentTaxRates);
                addTaxItemsFromInvoiceToArray(taxItems, invoice, true);
                let havePP = allInvoices.some(sInv => sInv.referentDocumentNumber === invoice.invoiceNumber);
                if (!havePP) {
                    addPaymentMethodFromInvoiceToArray(paymentMethods, invoice, true);
                }
                addCashiersAmountFromInvoiceToArray(allCashiers, invoice, true);
                addRefundTaxItemsFromInvoiceToArray(taxRefundItems, invoice, true);
            }
        }
    }
    addItemFromInvoiceToArrayByTax(amountByTax, allItems, currentTaxRates);
    taxItems = concatItemsForTax(taxItems, amountByTax);
    taxRefundItems = concatItemsForTax(taxRefundItems, amountByTax);
    return {
        items: allItems,
        refundItems: refundItems,
        taxRefund: taxRefundItems,
        tax: taxItems,
        methods: paymentMethods,
        cashiers: allCashiers,
        totalSum: normalSum - refundedSum,
        totalRefund: refundedSum,
        numberOfInvoicesSale: numberOfSaleInvoices,
        numberOfInvoicesRefund: numberOfRefundInvoices
    };
});

export const fetchTableReport = createAsyncThunk("fetchTableReport", async (data) => {
    const {location, currentTaxRates, tableUid} = data;
    let allInvoices = await getAllOfflineInvoiceByTableUid(location, tableUid);
    let returnObject = {
        tableUid: tableUid,
        quantitySale: 0,
        quantityRefund: 0,
        totalAmountSale: 0,
        totalAmountRefund: 0,
        taxSale: 0,
        taxRefund: 0,
        saleItems: [],
        refundItems: [],
        sale: {},
        refund: {}
    };
    for (let invoice of allInvoices) {
        let month = moment(invoice.sdcDateTime).get("month");
        let year = moment(invoice.sdcDateTime).get("year");
        for (let item of invoice.items) {
            const isRefound = invoice.transactionType === TRANSACTION_TYPE.refund;
            addItemFromInvoiceToArray(isRefound ? returnObject.refundItems : returnObject.saleItems,
                invoice, isRefound, currentTaxRates);
            if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                let sale = returnObject.sale;
                if (!sale[year]) {
                    sale = {
                        ...sale,
                        [year]: {
                            [month]: 1
                        }
                    };
                } else {
                    if (!sale[year][month]) {
                        sale[year] = {
                            ...sale[year],
                            [month]: 1
                        };
                    } else {
                        sale[year][month] = sale[year][month] + parseFloat(item.quantity);
                    }
                }
                returnObject = {
                    ...returnObject,
                    quantitySale: returnObject.quantitySale + parseFloat(item.quantity),
                    totalAmountSale: returnObject.totalAmountSale + parseFloat(item.totalAmount),
                    taxSale: returnObject.taxSale + calculatePdv([{
                        unitPrice: item.totalAmount,
                        quantity: 1,
                        vat: item.labels[0]
                    }], currentTaxRates),
                    sale: sale
                };
            } else if (invoice.transactionType === TRANSACTION_TYPE.refund) {
                let refund = returnObject.refund;
                if (!refund[year]) {
                    refund = {
                        ...refund,
                        [year]: {
                            [month]: 1
                        }
                    };
                } else {
                    if (!refund[year][month]) {
                        refund[year] = {
                            ...refund[year],
                            [month]: 1
                        };
                    } else {
                        refund[year][month] = refund[year][month] + 1;
                    }
                }
                returnObject = {
                    ...returnObject,
                    quantityRefund: returnObject.quantityRefund + parseFloat(item.quantity),
                    totalAmountRefund: returnObject.totalAmountRefund + parseFloat(item.totalAmount),
                    taxRefund: returnObject.taxRefund + calculatePdv([{
                        unitPrice: item.totalAmount,
                        quantity: 1,
                        vat: item.labels[0]
                    }], currentTaxRates),
                    refund
                };
            }
        }
    }
    return returnObject;
});

export const fetchItemReport = createAsyncThunk("fetchItemReport", async (data) => {
    const {location, currentTaxRates, itemName, groupName, uid} = data;
    let allInvoices = await getAllOfflineInvoiceForReportByItemNameOrGroupName(location, itemName, groupName);
    let returnObject = {
        name: itemName,
        labels: [],
        quantitySale: 0,
        quantityRefund: 0,
        totalAmountSale: 0,
        totalAmountRefund: 0,
        taxSale: 0,
        taxRefund: 0,
        sale: {},
        refund: {}
    };
    for (let invoice of allInvoices) {
        let month = moment(invoice.sdcDateTime).get("month");
        let year = moment(invoice.sdcDateTime).get("year");
        for (let item of invoice.items) {
            if (item.uid === uid || item.name.startsWith(itemName)) {
                if (invoice.transactionType === TRANSACTION_TYPE.sale) {
                    let sale = returnObject.sale;
                    if (!sale[year]) {
                        sale = {
                            ...sale,
                            [year]: {
                                [month]: 1
                            }
                        };
                    } else {
                        if (!sale[year][month]) {
                            sale[year] = {
                                ...sale[year],
                                [month]: 1
                            };
                        } else {
                            sale[year][month] = sale[year][month] + parseFloat(item.quantity);
                        }
                    }
                    returnObject = {
                        ...returnObject,
                        quantitySale: returnObject.quantitySale + parseFloat(item.quantity),
                        totalAmountSale: returnObject.totalAmountSale + parseFloat(item.totalAmount),
                        taxSale: returnObject.taxSale + calculatePdv([{
                            unitPrice: item.totalAmount,
                            quantity: 1,
                            vat: item.labels[0]
                        }], currentTaxRates),
                        sale: sale
                    };
                } else if (invoice.transactionType === TRANSACTION_TYPE.refund) {
                    let refund = returnObject.refund;
                    if (!refund[year]) {
                        refund = {
                            ...refund,
                            [year]: {
                                [month]: 1
                            }
                        };
                    } else {
                        if (!refund[year][month]) {
                            refund[year] = {
                                ...refund[year],
                                [month]: 1
                            };
                        } else {
                            refund[year][month] = refund[year][month] + 1;
                        }
                    }
                    returnObject = {
                        ...returnObject,
                        quantityRefund: returnObject.quantityRefund + parseFloat(item.quantity),
                        totalAmountRefund: returnObject.totalAmountRefund + parseFloat(item.totalAmount),
                        taxRefund: returnObject.taxRefund + calculatePdv([{
                            unitPrice: item.totalAmount,
                            quantity: 1,
                            vat: item.labels[0]
                        }], currentTaxRates),
                        refund
                    };
                }
            }
        }
    }
    return returnObject;
});

export const fetchDpuReport = createAsyncThunk("fetchDpuReport", async (data) => {
    const {startDate, endDate, location} = data;
    let allInvoices = await getAllOfflineInvoiceForReportBySdcDateTime(
        startDate.format("YYYY-MM-DDTHH:mm:ss"), endDate.format("YYYY-MM-DDTHH:mm:ss"), location);
    let factures = await getAllFacturesByDateFromFirestore(startDate.toDate(),
        endDate.toDate());
    let currentItemsState = await itemsStateOnDate(startDate);
    let orderedItems = [];
    for (const facture of factures) {
        for (const item of facture.items) {
            let index = orderedItems.findIndex(obj => obj.uid === item.uid);
            if (index === -1) {
                orderedItems.push(item);
            } else {
                orderedItems[index] = {
                    ...orderedItems[index],
                    quantity: orderedItems[index].quantity + item.quantity
                };
            }
        }
    }
    let arr = [];
    let allItems = [];
    for (let invoice of allInvoices) {
        if (invoice.transactionType === TRANSACTION_TYPE.sale) {
            for (const item of invoice.items) {
                let index = allItems.findIndex(obj => obj.uid === item.uid &&
                    parseFloat(obj.unitPrice).toFixed(2) === parseFloat(item.unitPrice).toFixed(2));
                if (index === -1) {
                    allItems.push(item);
                } else {
                    allItems[index] = {
                        ...allItems[index],
                        quantity: allItems[index].quantity + item.quantity
                    };
                }
            }
        }
    }
    for (const item of allItems) {
        let onlineItem = await getProductFromRealtimeDbByUid(item.uid);
        let isNormative = !!onlineItem.ingredients;
        let orderedItemIndex = orderedItems.findIndex(obj => obj.uid === item.uid);
        if (isNormative) {
            for (const ingredientKey of Object.keys(onlineItem.ingredients)) {
                let ingIndex = arr.findIndex(it => it.uid === ingredientKey);
                let ing = await getIngredientFromRealtimeDbByUid(ingredientKey);
                if (ingIndex === -1) {
                    arr.push({
                        uid: ingredientKey,
                        name: ing.name,
                        tax: ing.vat,
                        unit: ing.unit,
                        qtyBefore: currentItemsState[ingredientKey] || 0,
                        drink: 0,
                        meal: 0,
                        qtyToday: orderedItemIndex === -1 ? 0 : orderedItems[orderedItemIndex].quantity,
                        quantity: onlineItem.ingredients[ingredientKey].quantity,
                        price: ing.purchasePrice
                    });
                } else {
                    arr[ingIndex] = {
                        ...arr[ingIndex],
                        quantity: arr[ingIndex].quantity + onlineItem.ingredients[ingredientKey].quantity
                    };
                }
            }
        } else {
            let isFood = item?.restaurantItemsCategory === "Hrana"
            let isDrink = item?.restaurantItemsCategory === "Piće"

            arr.push({
                uid: item.uid,
                name: item.name,
                tax: item.labels[0],
                unit: item.unit,
                qtyBefore: currentItemsState[item.uid] || 0,
                drink: isDrink ? 1 : 0,
                meal: isFood ? 1 : 0,
                qtyToday: orderedItemIndex === -1 ? 0 : orderedItems[orderedItemIndex].quantity,
                quantity: item.quantity,
                price: item?.unitPrice ?? 0
            });
        }
    }
    return arr;
});

export const fetchFilterReport = createAsyncThunk("fetchFilterReport", async (data) => {
    const {
        startDate, endDate, location, currentTaxRates, operator, invType, itemGroup,
        itemCategory, taxLabel, item, customer
    } = data;
    let allInvoices = await getAllOfflineInvoiceForReportBySdcDateTime(
        startDate.format("YYYY-MM-DDTHH:mm:ss"), endDate.format("YYYY-MM-DDTHH:mm:ss"), location);
    if (invType) {
        allInvoices = allInvoices.filter(invoice => invoice.transactionType === invType);
    }
    if (operator) {
        allInvoices = allInvoices.filter(invoice => invoice.cashier === operator);
    }
    if (customer) {
        allInvoices = allInvoices.filter(invoice => invoice.buyerTin?.includes(customer.tin) ||
            invoice.buyerTin?.includes(customer.jmbg));
    }
    let allCashiers = [];
    let allItems = [];
    let refundItems = [];
    // let amountByTax = [];
    let paymentMethods = [];
    let taxItems = [];
    let taxRefundItems = [];
    let refundedSum = 0;
    let normalSum = 0;
    let numberOfSaleInvoices = 0;
    let numberOfRefundInvoices = 0;
    for (let invoice of allInvoices) {
        if (invoice.transactionType === TRANSACTION_TYPE.sale) {
            normalSum = normalSum + parseFloat(invoice.totalAmount);
            numberOfSaleInvoices = numberOfSaleInvoices + 1;
            addItemFromInvoiceToArray(allItems, invoice, false, currentTaxRates);
            addTaxItemsFromInvoiceToArray(taxItems, invoice, false);
            addPaymentMethodFromInvoiceToArray(paymentMethods, invoice, false);
            if (item) {
                addCashiersAmountFromInvoiceForProductToArray(allCashiers, item, invoice, false);
            } else {
                addCashiersAmountFromInvoiceToArray(allCashiers, invoice, false);
            }
        } else if (invoice.transactionType === TRANSACTION_TYPE.refund) {
            refundedSum = refundedSum + parseFloat(invoice.totalAmount);
            numberOfRefundInvoices = numberOfRefundInvoices + 1;
            addItemFromInvoiceToArray(refundItems, invoice, true, currentTaxRates);
            addTaxItemsFromInvoiceToArray(taxItems, invoice, true);
            addPaymentMethodFromInvoiceToArray(paymentMethods, invoice, true);
            if (item) {
                addCashiersAmountFromInvoiceForProductToArray(allCashiers, item, invoice, true);
            } else {
                addCashiersAmountFromInvoiceToArray(allCashiers, invoice, true);
            }
            addRefundTaxItemsFromInvoiceToArray(taxRefundItems, invoice, true);
        }
    }
    let i = 0;
    let all = [];
    if (itemCategory) {
        allItems = allItems.filter((item) => item.itemCategory === itemCategory);
        refundItems = refundItems.filter((item) => item.itemCategory === itemCategory);
    }
    if (itemGroup) {
        allItems = allItems.filter((item) => item.itemGroup === itemGroup);
        refundItems = refundItems.filter((item) => item.itemGroup === itemGroup);
    }
    if (taxLabel) {
        allItems = allItems.filter((item) => item?.labels?.includes(taxLabel));
        refundItems = refundItems.filter((item) => item?.labels?.includes(taxLabel));
    }
    if (item) {
        allItems = allItems.filter((localItem) => localItem?.uid === item.uid || localItem.name.includes(item.name));
        refundItems = refundItems.filter((localItem) => localItem?.uid === item.uid || localItem.name.includes(item.name));
    }
    if (invType) {
        if (invType === TRANSACTION_TYPE.sale) {
            all.push({
                id: -1,
                name: "Prodati artikli"
            });
            all = all.concat(allItems.map((item, index) => ({
                id: index,
                taxLabel: item.labels[0],
                name: item.name,
                price: item.multiplePrices ? item.multiplePrices : item.unitPrice,
                qty: Number(Number(item.quantity).toFixed(3)),
                basic: Number(Number(item.basic).toFixed(2)),
                tax: Number(Number(item.tax).toFixed(2)),
                sum: Number(Number(item.totalAmount).toFixed(2))
            })));
            all.push({
                id: -6,
                name: "Sumiranje",
                qty: allItems.reduce((a, {quantity}) => a + quantity, 0),
                basic: allItems.reduce((a, {basic}) => a + basic, 0),
                tax: allItems.reduce((a, {tax}) => a + tax, 0),
                sum: allItems.reduce((a, {sum}) => a + sum, 0)
            });
        } else {
            all.push({
                id: -1,
                name: "Refundirani artikli"
            });
            all = all.concat(refundItems.map((item, index) => ({
                id: i + index,
                taxLabel: item.labels[0],
                name: item.name,
                price: item.multiplePrices ? item.multiplePrices : item.unitPrice,
                qty: Number(Number(item.quantity).toFixed(3)),
                basic: Number(Number(item.basic).toFixed(2)),
                tax: Number(Number(item.tax).toFixed(2)),
                sum: Number(Number(item.totalAmount).toFixed(2))
            })));
            all.push({
                id: -7,
                name: "Sumiranje",
                qty: refundItems.reduce((a, {quantity}) => a + quantity, 0),
                basic: refundItems.reduce((a, {basic}) => a + basic, 0),
                tax: refundItems.reduce((a, {tax}) => a + tax, 0),
                sum: refundItems.reduce((a, {totalAmount}) => a + totalAmount, 0)
            });
        }
    } else {
        all.push({
            id: -1,
            name: "Prodati artikli"
        });
        all = all.concat(allItems.map((item, index) => ({
            id: index,
            taxLabel: item.labels[0],
            name: item.name,
            price: item.multiplePrices ? item.multiplePrices : item.unitPrice,
            qty: Number(Number(item.quantity).toFixed(3)),
            basic: Number(Number(item.basic).toFixed(2)),
            tax: Number(Number(item.tax).toFixed(2)),
            sum: Number(Number(item.totalAmount).toFixed(2))
        })));
        all.push({
            id: -8,
            name: "Sumiranje",
            qty: allItems.reduce((a, {quantity}) => a + quantity, 0),
            basic: allItems.reduce((a, {basic}) => a + basic, 0),
            tax: allItems.reduce((a, {tax}) => a + tax, 0),
            sum: allItems.reduce((a, {totalAmount}) => a + totalAmount, 0)
        });
        i = allItems.length + 1;
        all.push({
            id: -2,
            name: "Refundirani artikli"
        });
        all = all.concat(refundItems.map((item, index) => ({
            id: i + index,
            taxLabel: item.labels[0],
            name: item.name,
            price: item.multiplePrices ? item.multiplePrices : item.unitPrice,
            qty: Number(Number(item.quantity).toFixed(3)),
            basic: Number(Number(item.basic).toFixed(2)),
            tax: Number(Number(item.tax).toFixed(2)),
            sum: Number(Number(item.totalAmount).toFixed(2))
        })));
        i = refundItems.length + 1;
        all.push({
            id: -9,
            name: "Sumiranje",
            qty: refundItems.reduce((a, {quantity}) => a + quantity, 0),
            basic: refundItems.reduce((a, {basic}) => a + basic, 0),
            tax: refundItems.reduce((a, {tax}) => a + tax, 0),
            sum: refundItems.reduce((a, {totalAmount}) => a + totalAmount, 0)
        });
    }
    all.push({
        id: -3,
        name: "Korisnici"
    });
    all = all.concat(allCashiers.map((item, index) => ({
        id: all.length + 1 + index,
        name: item.name,
        sum: item.amount - item.refund
    })));
    i = allCashiers.length;
    return all;
});

export const fetchWriteOffReport = createAsyncThunk("fetchWriteOffReport", async (data) => {
    const {startDate, endDate, currentTaxRates} = data;
    const all = await getAllWrittenOffProductsFromFirestoreForDate(startDate.toDate(), endDate.toDate());
    const endReport = [];
    let i = 0;
    let sum = 0;
    for (const item of all) {
        let tax = calculatePdv([{
            unitPrice: item.product?.price || 0,
            quantity: item.quantity,
            vat: item.product?.vat || "A"
        }], currentTaxRates);
        const price = item.product?.price || 0;
        const total = Number(Number(price * item.quantity).toFixed(2));
        endReport.push({
            id: i,
            name: item.product?.name || "",
            quantity: item.quantity,
            price,
            tax: Number(Number(tax).toFixed(2)),
            total,
            writeOfTime: item.writeOfTime
        });
        sum = sum + total;
        i = i + 1;
    }
    endReport.push({
        id: i + 1,
        name: "",
        quantity: "",
        price: "",
        tax: "Ukupno",
        total: sum,
        writeOfTime: ""
    });
    return endReport;
});

export const fetchItemCardReport = createAsyncThunk("fetchItemCardReport", async (data) => {
    const {startDate, endDate} = data;
    let factures = await getAllFacturesByDateFromFirestore(startDate.toDate(), endDate.toDate());
    let items = [];
    for (const facture of factures) {
        for (const item of facture.items) {
            const dbItem = await getProductFromRealtimeDbByUid(item.uid);
            const quantity = Number(Number(item.quantity).toFixed(3));
            let price = (Number(item.purchasePrice) * quantity) -
                ((Number(item.purchasePrice) * quantity) * (Number(item.discount) / 100));
            price = Number(Number(price).toFixed(2));
            const index = items.findIndex(lItem => lItem.uid === item.uid);
            if (index === -1) {
                items.push({
                    ...dbItem,
                    ...item,
                    quantity,
                    totalQuantity: quantity,
                    purchasePrice: price,
                    purchasePriceAvg: price,
                    total: price
                });
            } else {
                items[index] = {
                    ...items[index],
                    totalQuantity: Number(Number(items[index].totalQuantity + quantity).toFixed(3)),
                    total: Number(Number(items[index].total + price).toFixed(2)),
                    purchasePriceAvg: Number(Number((items[index].purchasePriceAvg + price) / 2).toFixed(2))
                };
            }
        }
    }
    return items;
});

export const fetchRepresentationReport = createAsyncThunk("fetchRepresentationReport", async (data) => {
    const {startDate, endDate, currentTaxRates} = data;
    const all = await getRepresentationsFromFirestoreForDate(startDate.toDate(), endDate.toDate());
    const response = [];
    for (let element of all) {
        let totalTax = 0;
        let totalBasic = 0;
        let sum = 0;
        let newItems = [];
        for (let order of element.orders) {
            for (const item of order.items) {
                let tax = calculatePdv([{
                    unitPrice: item?.price || 0,
                    quantity: item.quantity,
                    vat: item?.vat || "A"
                }], currentTaxRates);
                const total = Number(Number(item?.price * item.quantity).toFixed(2));
                const basic = total - tax;
                totalTax = totalTax + tax;
                totalBasic = totalBasic + basic;
                sum = sum + total;
                let index = newItems.findIndex(lItem => lItem.uid === item.uid);
                if (index === -1) {
                    newItems.push({
                        ...item,
                        tax,
                        total,
                        basic
                    })
                } else {
                    newItems[index] = {
                        ...newItems[index],
                        tax: newItems[index].tax + tax,
                        total: newItems[index].total + total,
                        basic: newItems[index].basic + basic,
                    }
                }
            }
        }
        response.push({
            ...element,
            items: newItems,
            totalTax,
            totalBasic,
            sum
        })
    }
    return response;
});

export const fetchChargeFreeReport = createAsyncThunk("fetchChargeFreeReport", async (data) => {
    const {startDate, endDate, currentTaxRates} = data;
    const all = await getChargeFreeInvoiceFromOfflineDB(startDate.toDate(), endDate.toDate());
    const response = [];
    for (let element of all) {
        let totalTax = 0;
        let totalBasic = 0;
        let sum = 0;
        let newItems = [];
        for (const item of element.items) {
            let tax = calculatePdv([{
                unitPrice: item?.price || 0,
                quantity: item.quantity,
                vat: item?.vat || "A"
            }], currentTaxRates);
            const total = Number(Number(item?.price * item.quantity).toFixed(2));
            const basic = total - tax;
            totalTax = totalTax + tax;
            totalBasic = totalBasic + basic;
            sum = sum + total;
            let index = newItems.findIndex(lItem => lItem.uid === item.uid);
            if (index === -1) {
                newItems.push({
                    ...item,
                    tax,
                    total,
                    basic
                })
            } else {
                newItems[index] = {
                    ...newItems[index],
                    tax: newItems[index].tax + tax,
                    total: newItems[index].total + total,
                    basic: newItems[index].basic + basic,
                }
            }
        }

        response.push({
            ...element,
            items: newItems,
            totalTax,
            totalBasic,
            sum
        })
    }

    return response;
});

const initialState = {
    filterReport: [],
    dpuReport: [],
    representations: [],
    chargeFree: [],
    itemsCard: [],
    writeOffReport: [],
    profitReport: [],
    costs: [],
    overviewReport: {
        items: [],
        refundItems: [],
        tax: [],
        methods: [],
        cashiers: [],
        taxRefund: [],
        totalSum: 0,
        totalRefund: 0,
        numberOfInvoicesSale: 0,
        numberOfInvoicesRefund: 0
    },
    itemReport: {
        name: "",
        labels: [],
        quantitySale: 0,
        quantityRefund: 0,
        totalAmountSale: 0,
        totalAmountRefund: 0,
        taxSale: 0,
        taxRefund: 0,
        sale: {},
        refund: {}
    },
    tableReport: {
        name: "",
        labels: [],
        quantitySale: 0,
        quantityRefund: 0,
        totalAmountSale: 0,
        totalAmountRefund: 0,
        saleItems: [],
        refundItems: [],
        taxSale: 0,
        taxRefund: 0,
        sale: {},
        refund: {}
    },
    loading: false
};

const slice = createSlice({
    name: "reports",
    initialState,
    extraReducers: {
        [fetchOverviewReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchOverviewReport.fulfilled]: (state, {payload}) => {
            state.overviewReport = payload;
            state.loading = false;
        },
        [fetchOverviewReport.rejected]: (state) => {
            state.loading = false;
        },
        [fetchCostsReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchCostsReport.fulfilled]: (state, {payload}) => {
            state.costs = payload;
            state.loading = false;
        },
        [fetchCostsReport.rejected]: (state) => {
            state.loading = false;
        },
        //fetchReport
        [fetchItemReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchItemReport.fulfilled]: (state, {payload}) => {
            state.itemReport = payload;
            state.loading = false;
        },
        [fetchItemReport.rejected]: (state) => {
            state.loading = false;
        },
        //fetchTableReport
        [fetchTableReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchTableReport.fulfilled]: (state, {payload}) => {
            state.tableReport = payload;
            state.loading = false;
        },
        [fetchTableReport.rejected]: (state) => {
            state.loading = false;
        },
        //fetchDpuReport
        [fetchDpuReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchDpuReport.fulfilled]: (state, {payload}) => {
            state.dpuReport = payload;
            state.loading = false;
        },
        [fetchDpuReport.rejected]: (state) => {
            state.loading = false;
        },
        //fetchWarehouseReport
        [fetchProfitReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchProfitReport.fulfilled]: (state, {payload}) => {
            state.profitReport = payload;
            state.loading = false;
        },
        [fetchProfitReport.rejected]: (state) => {
            state.loading = false;
        },
        //fetchFilterReport
        [fetchFilterReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchFilterReport.fulfilled]: (state, {payload}) => {
            state.filterReport = payload;
            state.loading = false;
        },
        [fetchFilterReport.rejected]: (state) => {
            state.loading = false;
        },
        // fetchWriteOffReport
        [fetchWriteOffReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchWriteOffReport.fulfilled]: (state, {payload}) => {
            const sortedData = [...payload].sort((a, b) =>
                moment(b.writeOfTime.seconds * 1000 + b.writeOfTime.nanoseconds / 1000000)
                    .diff(moment(a.writeOfTime.seconds * 1000 + a.writeOfTime.nanoseconds / 1000000))
            );

            state.writeOffReport = sortedData;
            state.loading = false;
        },
        [fetchWriteOffReport.rejected]: (state) => {
            state.loading = false;
        },
        // fetchItemCardReport
        [fetchItemCardReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchItemCardReport.fulfilled]: (state, {payload}) => {
            state.itemsCard = payload;
            state.loading = false;
        },
        [fetchItemCardReport.rejected]: (state) => {
            state.loading = false;
        },
        // fetchRepresentationReport
        [fetchRepresentationReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchRepresentationReport.fulfilled]: (state, {payload}) => {
            state.representations = payload;
            state.loading = false;
        },
        [fetchRepresentationReport.rejected]: (state) => {
            state.loading = false;
        },
        [fetchChargeFreeReport.pending]: (state) => {
            state.loading = true;
        },
        [fetchChargeFreeReport.fulfilled]: (state, {payload}) => {
            state.chargeFree = payload;
            state.loading = false;
        },
        [fetchChargeFreeReport.rejected]: (state) => {
            state.loading = false;
        }
    }
});

// Reducer
export default slice.reducer;