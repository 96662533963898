import PropTypes from "prop-types";
// @mui
import {
    Alert,
    Avatar,
    Box,
    Button,
    Card,
    Checkbox,
    Container,
    IconButton,
    InputAdornment,
    Link,
    Stack,
    Typography
} from "@mui/material";
import Iconify from "../../components/Iconify";
import { useEffect, useState } from "react";
import { dispatch, useSelector } from "../../redux/store";
import { fetchAllOperators } from "../../redux/slices/operators";
import { useAuthContext } from "../../auth/useAuthContext";
import { useSettingsContext } from "../../components/settings";
import { useSnackbar } from "notistack";
import FormProvider, { RHFTextField } from "../../components/hook-form";
import * as Yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { LoadingButton } from "@mui/lab";
import { PATH_AUTH, PATH_DASHBOARD } from "../../routes/path";
import { useNavigate } from "react-router-dom";
import { setOperatorInfo } from "../../redux/slices/user";
import { getAllPublicGroups } from "../../redux/slices/groupsPublic";
//

// ----------------------------------------------------------------------

OperatorLayout.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  illustration: PropTypes.string
};

// TODO pogledati da li je inactive operater zbog prikaza
export default function OperatorLayout() {
  const navigate = useNavigate();
  const { themeStretch } = useSettingsContext();
  const { enqueueSnackbar } = useSnackbar();

  const { logout } = useAuthContext();

  const { groups } = useSelector(state => state.groupsPublic);
  const { allOperators } = useSelector(state => state.operators);

  useEffect(() => {
    dispatch(fetchAllOperators());
    dispatch(getAllPublicGroups());
  }, []);


  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Došlo je do greške!", { variant: "error" });
    }
  };

  return (
    <Container maxWidth={themeStretch ? false : "xl"}>
      {allOperators.length === 0 ?
        <Box sx={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flex: 1,
          width: "100hw",
          height: "100vh"
        }}>
          <Typography variant={"h5"}>Operateri nisu pronađeni...</Typography>
        </Box> :
        <Box paddingY={5}
             gap={2}
             display="grid"
             gridTemplateColumns={{
               xs: "repeat(2, 1fr)",
               sm: "repeat(3, 1fr)",
               md: "repeat(4, 1fr)"
             }}
        >
          {allOperators.map(operator =>
            <OperatorCard key={`operators-${operator.uid}`}
                          data={operator} groups={groups}
                          enqueueSnackbar={enqueueSnackbar} navigate={navigate} />)}
        </Box>}
      <Button onClick={handleLogout}
              sx={{ position: "fixed", bottom: 25, right: 25 }} variant={"contained"}>Odjavi se</Button>

    </Container>
  );
}

const OperatorCard = ({ data, groups, enqueueSnackbar, navigate }) => {

  const { group, username, secret } = data;

  const [showPassword, setShowPassword] = useState(false);

  const OperatorLoginSchema = Yup.object().shape({
    password: Yup.string().required("Lozinka je obavezna!")
  });

  const defaultValues = {
    password: ""
  };

  const methods = useForm({
    resolver: yupResolver(OperatorLoginSchema),
    defaultValues
  });

  const {
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = methods;

  const findRoleName = (roleId) => {
    return groups?.find(g => g.uid === roleId)?.name;
  };

  const onSubmit = async (props) => {
    try {
      if (secret.toString() === props.password.toString()) {
        dispatch(setOperatorInfo(data));
        return navigate(PATH_DASHBOARD.general.app, { replace: true });
      }
      enqueueSnackbar("Lozinka nije validna!", { variant: "error" });
    } catch (error) {
      console.error(error);

      reset();

      setError("afterSubmit", {
        ...error,
        message: "Uneti kredencijali nisu validni!"
      });
    }
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      {!!errors.afterSubmit && <Alert severity="error">{errors.afterSubmit.message}</Alert>}

      <Card
        sx={{
          py: 5,
          px: 5,
          display: "flex",
          position: "relative",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <Stack direction="row" alignItems="center" sx={{ top: 8, right: 8, position: "absolute" }}>
          {group === 31 &&
            <Checkbox
              icon={<Iconify icon="eva:star-outline" />}
              checkedIcon={<Iconify icon="eva:star-fill" />}
              checked={true}
              sx={{ p: 0.75 }}
            />}
        </Stack>
        <Avatar alt={username} src={"avatarUrl"} sx={{ width: 64, height: 64, mb: 3 }} />

        <Link variant="subtitle2" color="text.primary">
          {findRoleName(group)}
        </Link>

        <Link variant="subtitle1" color="text.primary">
          {username.toUpperCase()}
        </Link>

        <RHFTextField
          name="password"
          label="Lozinka"
          type={showPassword ? "text" : "password"}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"} />
                </IconButton>
              </InputAdornment>
            )
          }}
        />

        <LoadingButton
          fullWidth
          color="inherit"
          size="small"
          type="submit"
          variant="contained"
          loading={isSubmitting}
          sx={{
            mt: 1,
            bgcolor: "text.primary",
            color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800"),
            "&:hover": {
              bgcolor: "text.primary",
              color: (theme) => (theme.palette.mode === "light" ? "common.white" : "grey.800")
            }
          }}
        >
          Prijavi se
        </LoadingButton>

      </Card>
    </FormProvider>
  );
};
