import { child, get, limitToFirst, onValue, orderByKey, query, ref, startAfter, update } from "firebase/database";
import {
  getAllOfflineInvoiceForReportBySdcDateTime,
  getLastInsertedOfflineItem,
  putItemOffline,
  storeErrorToOffline,
  updateItemOffline
} from "../../store/offlineDb";
import { getAllFacturesByEndDateFromFirestore } from "../firestore/factures";
import moment from "moment";
import { CHANGE_ITEM_QUANTITY, TRANSACTION_TYPE } from "../../constants";
import { AUTH, DB, dbRef } from "../../auth/FirebaseContext";
import { addMessage } from "../../redux/slices/notifications";


export async function insetAllItemsOffline(setLoaded) {
  return new Promise(async (resolve, reject) => {
    await getItem(null, resolve, reject, setLoaded);
  });
}

export async function getItem(lastData, resolve, reject, setLoaded) {
  if (lastData === null) {
    lastData = await getLastInsertedOfflineItem();
  }
  try {
    const userItemsDbRef = ref(DB, `users/${AUTH.currentUser.uid}/private/items`);
    await get50RecordsOnRealTimeDB(userItemsDbRef, lastData ? lastData.uid : null, []).then(async value => {
      // 1000 loaded * 100 / 1660
      let i = 1;
      for (const item of value) {
        setLoaded(Math.floor(((i * 100)) / value.length));
        let publicItem = (await get(child(dbRef, `public/items/${item.uid}`))).val();
        await putItemOffline({
          ...publicItem,
          ...item
        });
        i++;
      }
    });
    resolve(false);
  } catch (e) {
    reject(undefined);
  }
}

export async function get50RecordsOnRealTimeDB(dbRef, lastData, arr) {
  return new Promise((async resolve => {
    await repeat(dbRef, lastData, arr, resolve);
  }));
}

async function repeat(dbRef, lastData, arr, resolve) {
  let q;
  if (lastData) {
    q = query(dbRef, orderByKey(), limitToFirst(200), startAfter(lastData));
  } else {
    q = query(dbRef, orderByKey(), limitToFirst(200));
  }
  await onValue(q, async (snapshot) => {
    let snapshotSize = snapshot.size;
    if (snapshotSize === 0) {
      return resolve(arr);
    } else {
      let i = 0;
      snapshot.forEach(child => {
        let parsedChild = child.val();
        arr.push({
          ...parsedChild,
          id: i,
          uid: child.key
        });
        if (i === snapshotSize - 1) {
          repeat(dbRef, child.key, arr, resolve);
        }
        i = i + 1;
      });
    }
  });
}

export async function changeItemQuantity(uid, quantity, operation, isItem, dispatch) {
  try {
    let item = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`))).val();
    if (!item) {
      item = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${uid}`))).val();
    }
    item = {
      ...item,
      uid: uid
    };
    //ovo ne bi smelo nikad da se desi
    if (item.ingredients) {
      await changeItemIngredientsQty(item, quantity, operation, dispatch);
    } else {
      let itemQuantity;
      if (item.quantity) {
        itemQuantity = operation === CHANGE_ITEM_QUANTITY.reduce ?
          Number(Number(item.quantity) - Number(parseFloat(quantity).toFixed(3)))
          : Number(Number(item.quantity) + Number(parseFloat(quantity).toFixed(3)));
      } else {
        itemQuantity = quantity;
      }
      await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`),
        {
          ...item,
          quantity: itemQuantity
        });
      if (dispatch) {
        // TODO videti sta sa ovim
        // dispatch(updateLocalItemQuantity({
        //     itemUid: uid,
        //     quantity: itemQuantity
        // }))
        await updateItemOffline({
          ...item,
          quantity: itemQuantity
        });
      } else {
        await updateItemOffline({
          ...item,
          quantity: itemQuantity
        });
      }
      addAlertMessage(dispatch, item, itemQuantity, true);
    }
  } catch (e) {
    console.error(changeItemQuantity, e);
    storeErrorToOffline("changeItemQuantity", "RealtimeDatabase.js", [uid, quantity,
      operation, isItem], e?.toString());
  }
}

//TODO minimalQuantity dodati
async function changeItemIngredientsQty(item, quantity, operation, dispatch) {
  let ingredients = item.ingredients;
  let ingredientsKeys = Object.keys(item.ingredients);
  for (let i = 0; i < ingredientsKeys.length; i++) {
    let ingredientUid = ingredientsKeys[i];
    let needForMakeQty = ingredients[ingredientUid].quantity;
    let ingItem = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientUid}`))).val();
    let ingQty;
    if (operation === CHANGE_ITEM_QUANTITY.reduce) {
      ingQty = parseFloat((Number(ingItem.quantity) - (Number(quantity) * Number(parseFloat(needForMakeQty))).toFixed(3)));
    } else {
      ingQty = Number((Number(ingItem.quantity) + (Number(quantity) * Number(parseFloat(needForMakeQty))).toFixed(3)));
    }
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientUid}`),
      {
        ...ingItem,
        quantity: ingQty
      });
    if (dispatch) {
      // TODO videti sta sa ovim
      // dispatch(updateLocalIngredientQuantity({
      //     itemUid: ingredientUid,
      //     quantity: ingQty
      // }))
    }
    addAlertMessage(dispatch, ingItem, ingQty, false);
  }
}

const addAlertMessage = (dispatch, item, quantity, isItem) => {
  if (dispatch) {
    if (item?.minimalQuantity !== undefined && ((item.quantity - quantity) < item?.minimalQuantity)) {
      let code = isItem ? "Proizvod sa šifrom: " : "Sastojak sa šifrom: ";
      let name = item.name ? item.name : code + item.code;
      dispatch(addMessage({
        body: name + " ostalo još: " + quantity,
        date: new Date().toString(),
        isRead: false,
        title: " Premala količina: " + name
      }));
    }
  }
};

export async function getProductFromRealtimeDbByUid(uid) {
  const publicItem = await (await get(child(dbRef, `public/items/${uid}`))).val();
  const privateItem = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${uid}`))).val();
  return {
    ...publicItem,
    ...privateItem
  };
}

export async function getCostsFromRealtimeDbByUid(uid) {
  return await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/costs/${uid}`))).val();
}

export async function getIngredientFromRealtimeDbByUid(uid) {
  return await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${uid}`))).val();
}

export async function checkIfProductHaveIngredients(item, quantity) {
  let ingredients = item.ingredients;
  let ingredientsKeys = Object.keys(item.ingredients);
  let response = {
    haveQuantity: true,
    productName: ""
  };
  for (const ingredientsKey of ingredientsKeys) {
    let needForMakeQty = ingredients[ingredientsKey].quantity;
    let ingItem = await (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/ingredients/${ingredientsKey}`))).val();
    let ingQty = parseFloat((parseFloat(ingItem.quantity) - (parseFloat(quantity) * parseFloat(needForMakeQty))).toFixed(3));
    if (ingQty < 0) {
      response = {
        haveQuantity: false,
        productName: ingItem.name
      };
      return response;
    }
  }
  return response;
}

// preneta kolicina = (sve fakture do tog dana - svi izdati racuni do tog dana)
// TODO Sta raditi za iteme koji su refundirani?
export async function itemsStateOnDate(date) {
  try {
    let dateForBefore = moment(date).set("hour", 0).set("minute", 0).set("second", 0);
    let facturesBefore = await getAllFacturesByEndDateFromFirestore(dateForBefore.toDate());
    let allInvoicesBefore = await getAllOfflineInvoiceForReportBySdcDateTime(
      moment(new Date(2020, 1, 1, 0, 0, 0)).format("YYYY-MM-DDTHH:mm:ss"),
      dateForBefore.format("YYYY-MM-DDTHH:mm:ss"));
    let allItems = {};
    for (const invoice of allInvoicesBefore) {
      if (invoice.transactionType === TRANSACTION_TYPE.sale) {
        for (const item of invoice.items) {
          if (item.uid) {
            allItems[item.uid] = allItems[item.uid] ? allItems[item.uid] - Number(item.quantity) : -Number(item.quantity);
          }
        }
      }
    }
    for (const facture of facturesBefore) {
      for (const item of facture.items) {
        allItems[item.uid] = allItems[item.uid] ? allItems[item.uid] + Number(item.quantity) : Number(item.quantity);
      }
    }
    return allItems;
  } catch (e) {
    console.error("itemsStateOnDate", e);
    return {};
  }
}

export async function itemsAveragePurchasePrice(date) {
  try {
    let dateForBefore = moment(date).set("hour", 0).set("minute", 0).set("second", 0);
    let facturesBefore = await getAllFacturesByEndDateFromFirestore(dateForBefore.toDate());
    let items = [];
    for (const facture of facturesBefore) {
      for (const item of facture.items) {
        let index = items.findIndex(obj => obj.uid === item.uid);
        if (index === -1) {
          items.push({
            uid: item.uid,
            sumPrices: Number(item.purchasePrice),
            counter: 1
          });
        } else {
          items[index] = {
            ...items[index],
            sumPrices: items[index].sumPrices + Number(item.purchasePrice),
            counter: items[index].counter + 1
          };
        }
      }
    }
    let objItems = {};
    for (const item of items) {
      objItems[item.uid] = item.sumPrices / item.counter;
    }
    return objItems;
  } catch (e) {
    console.error("itemAveragePurchasePrice", e);
    return {};
  }
}
