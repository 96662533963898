import { Navigate, useRoutes } from "react-router-dom";
import GuestGuard from "../guards/GuestGuard";
import DashboardLayout from "../layouts/dashboard";
import { PATH_AFTER_LOGIN } from "../config";
import {
    ActiveOrdersReportPage,
    ClientPreviewPage,
    ClientsPage,
    CompensationAddRemovePage,
    CompensationPage,
    CostsEditPage,
    CostsList,
    CostsNew,
    CostsReportPage,
    CreateOrderPage,
    CustomersEditPage,
    CustomersList,
    CustomersNew,
    DiscountsEditPage,
    DiscountsList,
    DiscountsNew,
    DiscountsUserNew,
    DiscountsUsersList,
    DiscountUserEditPage,
    DPUReportPage,
    FactureAdditionalPayment,
    FactureCreatePage,
    FactureEditPage,
    FacturePreview,
    FacturesPage,
    FilterReportPage,
    HomePage,
    IngredientCreatePage,
    IngredientEditPage,
    IngredientsPage,
    InventoryPage,
    InventoryPreviewPage,
    InvoicesPage,
    ItemsCardPage,
    ItemsEditPage,
    ItemsNewPage,
    ItemsPage,
    ItemsReportPage,
    Login,
    OperatorsEditPage,
    OperatorsList,
    OperatorsNew,
    OverviewPage,
    ProfitReportPage,
    RepresentationPage,
    SettingsPage,
    StartersItemsPage,
    StockListPage,
    SuppliersCreatePage,
    SuppliersEditPage,
    SuppliersPage,
    TableReportPage,
    TurnoverWithoutCompensationPage,
    WriteOffPage,
    WriteOffReportPage
} from "./elements";
import AuthGuard from "../auth/AuthGuard";
import { PATH_AUTH, PATH_DASHBOARD } from "./path";
import RoleBasedGuard from "../auth/RoleBasedGuard";
import OrdersLayout from "../layouts/Orders";

export default function Router() {
    return useRoutes([
        {
            path: "auth",
            children: [
                {
                    path: PATH_AUTH.login,
                    element: (
                        <GuestGuard>
                            <Login/>
                        </GuestGuard>
                    )
                }
            ]
        },
        {
            path: PATH_DASHBOARD.general.createOrder,
            element: (
                <AuthGuard>
                    <RoleBasedGuard>
                        <OrdersLayout/>
                    </RoleBasedGuard>
                </AuthGuard>
            ),
            children: [
                {element: <CreateOrderPage/>, index: true}
            ]
        },
        {
            path: "",
            element: (
                <AuthGuard>
                    <DashboardLayout/>
                </AuthGuard>
            ),
            children: [
                {element: <Navigate to={PATH_AFTER_LOGIN} replace/>, index: true},
                {path: PATH_DASHBOARD.general.app, element: <HomePage/>},
                {path: PATH_DASHBOARD.general.settings, element: <SettingsPage/>},
                {
                    path: PATH_DASHBOARD.reports.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.reports.overview} replace/>, index: true},
                        {path: PATH_DASHBOARD.reports.overview, element: <OverviewPage/>},
                        {path: PATH_DASHBOARD.reports.item, element: <ItemsReportPage/>},
                        {path: PATH_DASHBOARD.reports.costs, element: <CostsReportPage/>},
                        {path: PATH_DASHBOARD.reports.writeOff, element: <WriteOffReportPage/>},
                        {path: PATH_DASHBOARD.reports.dpu, element: <DPUReportPage/>},
                        {path: PATH_DASHBOARD.reports.activeOrders, element: <ActiveOrdersReportPage/>},
                        {path: PATH_DASHBOARD.reports.filter, element: <FilterReportPage/>},
                        {path: PATH_DASHBOARD.reports.profit, element: <ProfitReportPage/>},
                        {path: PATH_DASHBOARD.reports.table, element: <TableReportPage/>},
                        {path: PATH_DASHBOARD.reports.turnoverWithoutCompensation, element: <TurnoverWithoutCompensationPage/>},
                        {path: PATH_DASHBOARD.reports.itemCard, element: <ItemsCardPage/>},
                        {path: PATH_DASHBOARD.reports.representations, element: <RepresentationPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.compensation.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.compensation.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.compensation.list, element: <CompensationPage/>},
                        {path: PATH_DASHBOARD.compensation.editRemove, element: <CompensationAddRemovePage/>},
                    ]
                },
                {
                    path: PATH_DASHBOARD.items.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.items.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.items.list, element: <ItemsPage/>},
                        {path: PATH_DASHBOARD.items.new, element: <ItemsNewPage/>},
                        {path: PATH_DASHBOARD.items.starters, element: <StartersItemsPage/>},
                        {path: ":id/edit", element: <ItemsEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.costs.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.costs.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.costs.list, element: <CostsList/>},
                        {path: PATH_DASHBOARD.costs.new, element: <CostsNew/>},
                        {path: ":name/edit", element: <CostsEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.operators.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.operators.list}/>, index: true},
                        {path: PATH_DASHBOARD.operators.list, element: <OperatorsList/>},
                        {path: PATH_DASHBOARD.operators.new, element: <OperatorsNew/>},
                        {path: ":name/edit", element: <OperatorsEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.customers.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.customers.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.customers.list, element: <CustomersList/>},
                        {path: PATH_DASHBOARD.customers.new, element: <CustomersNew/>},
                        {path: ":name/edit", element: <CustomersEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.discounts.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.discounts.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.discounts.list, element: <DiscountsList/>},
                        {path: PATH_DASHBOARD.discounts.new, element: <DiscountsNew/>},
                        {path: PATH_DASHBOARD.discounts.customers, element: <DiscountsUsersList/>},
                        {path: PATH_DASHBOARD.discounts.customersNew, element: <DiscountsUserNew/>},
                        {path: ":name/edit", element: <DiscountsEditPage/>},
                        {path: ":name/editCustomer", element: <DiscountUserEditPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.warehouse.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.warehouse.suppliers} replace/>, index: true},
                        {path: PATH_DASHBOARD.warehouse.suppliers, element: <SuppliersPage/>},
                        {path: PATH_DASHBOARD.warehouse.newSupplier, element: <SuppliersCreatePage/>},
                        {path: PATH_DASHBOARD.warehouse.editSupplier, element: <SuppliersEditPage/>},
                        {path: PATH_DASHBOARD.warehouse.factures, element: <FacturesPage/>},
                        {path: PATH_DASHBOARD.warehouse.viewFacture, element: <FacturePreview/>},
                        {path: PATH_DASHBOARD.warehouse.addPaidAmountFacture, element: <FactureAdditionalPayment/>},
                        {path: PATH_DASHBOARD.warehouse.newFacture, element: <FactureCreatePage/>},
                        {path: PATH_DASHBOARD.warehouse.editFacture, element: <FactureEditPage/>},
                        {path: PATH_DASHBOARD.warehouse.stockList, element: <StockListPage/>},
                        {path: PATH_DASHBOARD.warehouse.ingredients, element: <IngredientsPage/>},
                        {path: PATH_DASHBOARD.warehouse.editIngredient, element: <IngredientEditPage/>},
                        {path: PATH_DASHBOARD.warehouse.createIngredient, element: <IngredientCreatePage/>},
                        {path: PATH_DASHBOARD.warehouse.inventory, element: <InventoryPage/>},
                        {path: PATH_DASHBOARD.warehouse.inventoryPreview, element: <InventoryPreviewPage/>},
                        {path: PATH_DASHBOARD.warehouse.writeOff, element: <WriteOffPage/>},
                        {path: PATH_DASHBOARD.warehouse.clients, element: <ClientsPage/>},
                        {path: PATH_DASHBOARD.warehouse.clientsPreview, element: <ClientPreviewPage/>}
                    ]
                },
                {
                    path: PATH_DASHBOARD.invoices.root,
                    children: [
                        {element: <Navigate to={PATH_DASHBOARD.invoices.list} replace/>, index: true},
                        {path: PATH_DASHBOARD.invoices.list, element: <InvoicesPage/>}
                    ]
                }
            ]
        }
    ]);
}


