import {deleteDoc, doc, getDoc, setDoc, Timestamp, updateDoc} from "firebase/firestore";
import {AUTH, DB} from "../../../auth/FirebaseContext";
import {putLogToFirebase, removeProductByOrderId} from "../../../store/offlineDb";
import {CHANGE_ITEM_QUANTITY, ORDER_TYPE} from "../../../constants";
import {changeItemQuantity} from "../../realtimeDatabase";
import {dispatch} from "../../../redux/store";
import {addWriteOffToFirestore} from "../writeOff";

export async function updateOrderTypeAndFinishPreparation(orderId, orderType, finishedPreparation) {
    updateDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`), {
        orderType: orderType,
        finishedPreparation: finishedPreparation
    }).then(_ => {
        console.debug("Uspesno apdejtovan ORDER na firestoru");
    }).catch(e => {
        putLogToFirebase("updateFinishPreparation", "firestore/index.js",
            [orderId, orderType, finishedPreparation], e.toString())
    })
}

export async function updateFinishPreparation(orderId, finishedPreparation) {
    updateDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`), {
        finishedPreparation: finishedPreparation
    }).then(_ => {
        console.debug("Uspesno apdejtovan ORDER na firestoru");
    }).catch(e => {
        putLogToFirebase("updateFinishPreparation", "firestore/index.js",
            [orderId, finishedPreparation], e.toString())
    })
}

export async function transferOrderToAnotherTableFirestore(order, tableUid) {
    const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${order.id}`);
    await updateDoc(docRef, {
        ...order,
        tableUid: tableUid
    });
}

export async function deleteOnlineOrder(order) {
    await deleteDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${order.id}`));
}

export async function addDeletedOnlineOrder(order) {
    await setDoc(doc(DB, "deletedOrders", "users", AUTH.currentUser.uid, order.id.toString()), {...order});
}
export async function updateActive(orderId, active) {
    await updateDoc(doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`), {
        active: active
    });
}

const parseItemsForFb = (items) => {
    return items.map(item => ({
        uid: item.uid,
        quantity: item.addedQuantity,
        price: item.price,
        name: item.name,
        note: item.note || null,
        prepTime: item.prepTime ? (item.prepTime !== 0 ? item.prepTime : 0) : 0,
        vat: item.vat,
        unit: item.unit,
        restaurantGroup: item?.restaurantGroup || false
    }));
};

export async function addOrderOnFirestore(order) {
    await setDoc(doc(DB, "orders", "users", AUTH.currentUser.uid, order.id.toString()), {
        active: true,
        alert: order.alert || null,
        name: order?.name || "",
        finishedPreparation: false,
        items: parseItemsForFb(order.items),
        layoutUid: order.layoutUid || null,
        orderTime: Timestamp.fromMillis(order.id),
        submitted: true,
        orderType: order.orderType || ORDER_TYPE.all,
        tableUid: order.tableUid,
        cashier: order.cashier
    });
}

export async function writeOffProductFromOrder(orderId, itemUid, quantity, isWriteOff) {
    await reduceItemQuantityForOrder(orderId, itemUid, quantity);
    await removeProductByOrderId(orderId, {
        uid: itemUid,
        quantity: quantity
    }, false);
    if (isWriteOff) {
        await changeItemQuantity(itemUid, quantity, CHANGE_ITEM_QUANTITY.reduce, true, dispatch);
        await addWriteOffToFirestore(orderId, itemUid, quantity);
    }
}

export async function reduceItemQuantityForOrder(orderId, itemUid, quantity) {
    const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`);
    const onlineOrder = (await getDoc(docRef)).data();
    let items = [...onlineOrder.items];
    let index = items.findIndex(orItem => orItem.uid === itemUid);
    let active = true;
    if (index !== -1) {
        let newQuantity = items[index].quantity - quantity;
        if (newQuantity <= 0) {
            items.splice(index, 1);
        } else {
            items[index] = {
                ...items[index],
                quantity: newQuantity
            };
        }
        if (items.length === 0)
            active = false;
    }
    await updateDoc(docRef, {
        active: active,
        items: items
    });
}

export async function updateItemNoteForOrder(orderId, item) {
    const docRef = doc(DB, `orders/users/${AUTH.currentUser.uid}/${orderId}`);
    const onlineOrder = (await getDoc(docRef)).data();
    let items = [...onlineOrder.items];
    let index = items.findIndex(orItem => orItem.uid === item.uid);
    if (index !== -1) {
        items[index] = {
            ...items[index],
            note: item.note
        };
    }
    await updateDoc(docRef, {
        items: items
    });
}