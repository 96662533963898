import axios from "axios";

const BASE_URL = "https://europe-central2-esir-44ade.cloudfunctions.net/taxCoreAPI"

export const customAxios = (token, pac) => axios.create({
    baseURL: BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        "Authorization": `Bearer ${token}`,
        "PAC": pac,
    },
    withCredentials: false,
});