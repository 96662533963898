import {createAsyncThunk, createSlice, current} from '@reduxjs/toolkit';
import {AUTH, dbRef, storageRef} from "../../auth/FirebaseContext";
import {child, get, push, set, update} from "firebase/database";
import {putItemsOffline} from "../../store/offlineDb";
import {getDownloadURL, ref, uploadBytes} from "firebase/storage";

export const addItemsFromCsv = createAsyncThunk("addItemsFromCsv", async (payload) => {
    await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), null);
    const newProducts = [];
    for (const item of payload) {
        try {
            const response = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), {
                // uid: item.uid || null,
                name: item.name,
                category: item.category,
                vat: item.vat,
                unit: item.unit,
                price: Number(item.price),
                ean: item.ean || null,
                code: item.code,
                restaurantGroup: item.restaurantGroup,
                restaurantItemsCategory: item.restaurantItemsCategory
            });
            newProducts.push({
                ...item,
                uid: response.key
            });
        } catch (e) {
        }
    }
    return newProducts;
});

export const addItem = createAsyncThunk("addItem", async (payload) => {
    const obj = removeNullUndefined(payload)
    if (payload?.avatarUrl) {
        let imageRef = ref(ref(storageRef, "public/itemphotos"), `${itemKey.key}.png`);
        await uploadBytes(imageRef, payload?.avatarUrl)
    } else {
        delete payload?.avatarUrl
    }
    const itemKey = await push(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`), {
        ...payload,
        inactive: false
    })

    return {...payload, uid: itemKey.key}
})

export const addStarter = createAsyncThunk("addStarter", async (payload) => {
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${payload.uid}`), {
        isStarter: payload.isStarter
    });
})
export const updateItem = createAsyncThunk("updateItem", async (payload) => {
    const obj = removeNullUndefined(payload)
    if (payload?.avatarUrl) {
        let imageRef = ref(ref(storageRef, "public/itemphotos"), `${payload.uid}.png`);
        await uploadBytes(imageRef, payload?.avatarUrl)
    }
    await set(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${obj.uid}`), {
        ...obj,
        inactive: false
    });
    return {...obj, inactive: false}
})

function removeNullUndefined(obj) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            if (obj[key] === null || typeof obj[key] === 'undefined') {
                delete obj[key];
            }
        }
    }
    return obj;
}
export const fetchItemLogoUrl = createAsyncThunk('fetchItemLogoUrl', async (payload) => {
    const imageRef = ref(ref(storageRef, "public/itemphotos"), `${payload.uid}.png`);
    return await getDownloadURL(imageRef);
})
export const addMultipleItems = createAsyncThunk('addMultipleItems', async (payload) => {

})

export const deleteItem = createAsyncThunk('deleteItem', async (payload) => {
    await update(child(dbRef, `users/${AUTH.currentUser.uid}/private/items/${payload.uid}`), {
        ...payload,
        inactive: true
    });
    return {...payload, inactive: !payload.inactive}
})

export const fetchAllMergedItems = createAsyncThunk('fetchAllMergedItems', async () => {
    let allPrivateProducts = [];
    (await get(child(dbRef, `users/${AUTH.currentUser.uid}/private/items`))).forEach(item => {
        allPrivateProducts.push({
            ...item.val(),
            uid: item.key
        });
    });
    let publicProducts = [];
    (await get(child(dbRef, `public/items`))).forEach(item => {
        publicProducts.push({
            ...item.val(),
            uid: item.key
        });
    })
    const categories = {};
    const arr = []
    allPrivateProducts.forEach(item => {
        let index = publicProducts.findIndex(v => v.uid === item.uid);

        const mergedItem = index === -1 ? item :
            {
                ...publicProducts[index],
                ...item,
                price: item?.price > 0 ? item.price : 0
            }
        if (mergedItem.name && mergedItem.code && mergedItem.price && mergedItem.inactive !== true) {
            arr.push(mergedItem)
            categories[mergedItem?.category?.toUpperCase()] = true;
        }
    })
    await putItemsOffline(arr)
    return {items: arr, categories: Object.keys(categories)};
})

const initialState = {
    allItemsList: [],
    allItemsCategories: [],
    loading: false,
    error: undefined
}

export const itemsSlice = createSlice({
    name: 'items',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchAllMergedItems.pending]: (state) => {
            state.loading = true;
        },
        [fetchAllMergedItems.fulfilled]: (state, {payload}) => {
            state.allItemsList = payload.items
            state.allItemsCategories = payload.categories
            state.loading = false;
        },
        [fetchAllMergedItems.rejected]: (state) => {
            state.loading = false;
        },
        [addItem.pending]: (state) => {
            state.loading = true;
        },
        [addItem.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allItemsList)]
            arr.push(payload)
            state.allItemsList = arr
            state.error = undefined
        },
        [addItem.rejected]: (state) => {
            state.loading = false;
        },
        [updateItem.pending]: (state) => {
            state.loading = true;
        },
        [updateItem.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allItemsList)]
            const index = arr.findIndex(i => i.uid === payload.uid)
            if (index !== -1) {
                arr[index] = payload
                state.allItemsList = arr
            }
            state.error = undefined
        },
        [updateItem.rejected]: (state) => {
            state.loading = false;
        },
        [addStarter.pending]: (state) => {
            state.loading = true;
        },
        [addStarter.fulfilled]: (state, {payload}) => {
            // const arr = [...current(state.allItemsList)]
            // const index = arr.findIndex(i => i.uid === payload.uid)
            // if (index !== -1) {
            //     arr[index] = payload
            //     state.allItemsList = arr
            // }
            // state.error = undefined
        },
        [addStarter.rejected]: (state) => {
            state.loading = false;
        },
        [deleteItem.pending]: (state) => {
            state.loading = true;
        },
        [deleteItem.fulfilled]: (state, {payload}) => {
            const arr = [...current(state.allItemsList)]
            const index = arr.findIndex(i => i.uid === payload.uid)
            arr.splice(index, 1)
            state.allItemsList = arr
            state.error = undefined
        },
        [deleteItem.rejected]: (state) => {
            state.loading = false;
        },
        [addMultipleItems.pending]: (state) => {
            state.loading = true;
        },
        [addMultipleItems.fulfilled]: (state, {meta}) => {
            state.loading = false;
        },
        [addMultipleItems.rejected]: (state, action) => {
            state.loading = false;
        },
        [addItemsFromCsv.pending]: (state) => {
            state.loadingProducts = true;
        },
        [addItemsFromCsv.fulfilled]: (state, {payload}) => {
            let products = [...current(state.allItemsList)]
            products.push(payload)
            state.allItemsList = products;
            state.loadingProducts = false;
        },
        [addItemsFromCsv.rejected]: (state) => {
            state.loadingProducts = false;
        }
    }
});

export const itemsReducer = itemsSlice.reducer
